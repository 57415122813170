import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Button } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, LoadingBox, Permitted } from 'components'

import helpers from 'helpers'
import useStyles from './styles'

import constants from 'constants/index'
import { routes } from 'Routes'

const TableRowPartnerServices = ({
  partnerService,
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  const history = useHistory()

  const handleDetails = (partnerId, partnerServiceId) => {
    history.push(
      reverse(routes.partnerServices.show, {
        partnerId,
        partnerServiceId,
      }),
    )
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{partnerService.id}</TableCell>
        <TableCell overFlowSize="200px">
          {partnerService.companyService.name}
        </TableCell>
        <TableCell overFlowSize="200px">
          {partnerService.partner.name}
        </TableCell>
        <TableCell overFlowSize="200px">{partnerService.description}</TableCell>
        <TableCell>
          {helpers.partnerServices.formatPrice(partnerService)}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(partnerService.createdAt)}
        </TableCell>
        <TableCell align="right" disableTooltip>
          <MenuButton>
            <Permitted tag={constants.permissions.COMPANY_SERVICES.SHOW}>
              <Button
                fullWidth
                onClick={() =>
                  handleDetails(partnerService.partner.id, partnerService.id)
                }
              >
                Detalhes
              </Button>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
    </>
  )
}

const PartnerServicesTable = ({
  partnerServices,
  isLoading,
  onEvent = () => {},
  setLoading = () => {},
}) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum serviço dos parceiros encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow className={classes.tableHeaderHeight}>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="20%">Nome do serviço</TableCell>
                  <TableCell width="20%">Nome do parceiro</TableCell>
                  <TableCell width="20%">Descrição</TableCell>
                  <TableCell width="10%">Preço</TableCell>
                  <TableCell width="10%">Data de criação</TableCell>
                  <TableCell width="10%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerServices?.map((partnerService) => (
                  <TableRowPartnerServices
                    partnerService={partnerService}
                    key={partnerService.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default PartnerServicesTable
