import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import usePagination from 'hooks/usePagination'
import useSnackbar from 'hooks/useSnackbar'

import { useTheme } from '@material-ui/styles'

import { reverse } from 'named-urls'

import {
  Container,
  ContentHeader,
  LoadingBox,
  MenuButton,
  Page,
  Permitted,
  BaseActionDialog as ChangeStatusDialog,
  BaseActionDialog as DeleteCompanyServiceDialog,
} from 'components'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Tabs,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { CompanyServiceDetails, PartnersServiceTable } from './components'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'
import helpers from 'helpers'
import useStyles from './styles'

const CompanyServicesShow = ({ match }) => {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const snackbar = useSnackbar()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [companyService, setCompanyService] = useState()
  const [partnersServiceResponse, setPartnersServiceResponse] = useState()
  const [isLoading, setLoading] = useState(true)
  const [changeStatusDialog, setChangeStatusDialog] = useState(false)
  const [deleteCompanyServiceDialog, setDeleteCompanyServiceDialog] =
    useState(false)
  const [reload, setReload] = useState(0)
  const { companyServiceId } = match.params

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  useEffect(() => {
    const loadCompanyService = async () => {
      let response = await service.marketPlace.companyService.get({
        companyServiceId,
      })

      setCompanyService(response?.data?.companyService)
    }

    const loadPartnersService = async () => {
      let response = await service.marketPlace.companyService.partnersService(
        {
          companyServiceId,
          perPage,
          page,
        },
        [page, perPage],
      )
      setPartnersServiceResponse(response?.data)
    }

    loadCompanyService()
      .then(() => {
        loadPartnersService()
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 200)
      })
  }, [reload, companyServiceId, page, perPage])

  const handleEdit = () => {
    history.push(
      reverse(routes.companyServices.edit, {
        companyServiceId: companyServiceId,
      }),
    )
  }

  const handleChangeStatus = async () => {
    setLoading(true)

    let data = {
      status:
        constants.companyService.CHANGE_STATUS_VALUE[companyService.status],
    }

    try {
      await service.marketPlace.companyService.put({
        companyServiceId: companyService.id,
        companyService: data,
      })
      snackbar.open({
        message:
          constants.companyService.CHANGE_STATUS_SUCCESS_MESSAGES[
            companyService.status
          ],
        variant: 'success',
      })
      setChangeStatusDialog(false)
      setLoading(false)
      setReload(reload + 1)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleDelete = async () => {
    setLoading(true)

    try {
      await service.marketPlace.companyService.destroy({
        companyServiceId: companyService.id,
      })
      snackbar.open({
        message: 'Serviço excluído com sucesso',
        variant: 'success',
      })
      setDeleteCompanyServiceDialog(false)
      setLoading(false)
      history.push(routes.companyServices.all)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <Page title="Detalhes do serviço da empresa">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Serviços da empresa"
          subtitle="Detalhes"
        />
        <>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              <Card>
                <Box p={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="h5">Informações</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Permitted
                          someTags={[
                            constants.permissions.COMPANY_SERVICES.UPDATE,
                            constants.permissions.COMPANY_SERVICES.DELETE,
                          ]}
                        >
                          <MenuButton>
                            <Permitted
                              tag={
                                constants.permissions.COMPANY_SERVICES.UPDATE
                              }
                            >
                              <Button fullWidth onClick={() => handleEdit()}>
                                Editar
                              </Button>
                            </Permitted>
                            <Permitted
                              tag={
                                constants.permissions.COMPANY_SERVICES.DELETE
                              }
                            >
                              <Button
                                fullWidth
                                onClick={() =>
                                  setDeleteCompanyServiceDialog(true)
                                }
                              >
                                Excluir
                              </Button>
                            </Permitted>
                            <Permitted
                              tag={
                                constants.permissions.COMPANY_SERVICES.UPDATE
                              }
                            >
                              <Button
                                fullWidth
                                onClick={() => setChangeStatusDialog(true)}
                              >
                                {helpers.companyServices.changeStatusButtonLabel(
                                  companyService.status,
                                )}
                              </Button>
                            </Permitted>
                          </MenuButton>
                        </Permitted>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box mt={1} p={2}>
                  <CompanyServiceDetails companyService={companyService} />
                </Box>
              </Card>
              <Box mt={4}></Box>
              <Tabs
                scrollButtons="on"
                textColor="primary"
                indicatorColor="primary"
                value="partnerServices"
                TabIndicatorProps={{ width: 'auto' }}
                variant="scrollable"
              >
                <Tab
                  value="partnerServices"
                  className={classes.tabWidth}
                  label="Parceiros relacionados ao serviço"
                />
              </Tabs>
              <Divider />
              <Card>
                <Box display="flex" width="100%">
                  <Grid container>
                    <PartnersServiceTable
                      partnersService={partnersServiceResponse.partnerServices}
                    />
                  </Grid>
                </Box>
                <Box px={2} display="flex" justifyContent="flex-end">
                  <TablePagination
                    component="div"
                    count={partnersServiceResponse?.meta?.totalCount}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page - 1}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[1, 5, 10, 25, 100]}
                    labelRowsPerPage={isDesktop ? 'Por página' : ''}
                    nextIconButtonProps={{ size: 'small' }}
                    backIconButtonProps={{ size: 'small' }}
                  />
                </Box>
              </Card>
              <ChangeStatusDialog
                title={
                  constants.companyService.CHANGE_STATUS_DIALOG_TITLES[
                    companyService.status
                  ]
                }
                infoMessage={
                  constants.companyService.CHANGE_STATUS_DIALOG_INFO_MESSAGES[
                    companyService.status
                  ]
                }
                actionButtonText={
                  constants.companyService
                    .CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT[
                    companyService.status
                  ]
                }
                open={changeStatusDialog}
                maxWidth="sm"
                setOpen={setChangeStatusDialog}
                action={handleChangeStatus}
              />
              <DeleteCompanyServiceDialog
                title="Excluir serviço"
                infoMessage={
                  constants.companyService.DELETE_DIALOG_INFO_MESSAGE
                }
                actionButtonText="Excluir"
                maxWidth="sm"
                open={deleteCompanyServiceDialog}
                setOpen={setDeleteCompanyServiceDialog}
                action={handleDelete}
              />
            </>
          )}
        </>
      </Container>
    </Page>
  )
}

export default CompanyServicesShow
