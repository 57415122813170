import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.redirectLink.ACTIVE_STATUS:
      return 'ATIVO'
    case constants.redirectLink.INACTIVE_STATUS:
      return 'INATIVO'
    default:
      return '-'
  }
}

export default statusLabel
