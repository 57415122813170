import { useState } from 'react'

import BadgeContext from 'contexts/BadgeContext'

const BadgeProvider = ({ children }) => {
  const [
    badgePartnerServiceWaitingApproved,
    setBadgePartnerServiceWaitingApproved,
  ] = useState(0)

  const [badgePartnerWaitingApproved, setBadgePartnerWaitingApproved] =
    useState(0)

  return (
    <BadgeContext.Provider
      value={{
        badgePartnerServiceWaitingApproved,
        setBadgePartnerServiceWaitingApproved,
        badgePartnerWaitingApproved,
        setBadgePartnerWaitingApproved,
      }}
    >
      {children}
    </BadgeContext.Provider>
  )
}

export default BadgeProvider
