import THEMES from 'constants/theme'

const styles = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    height: '60px',
    backgroundColor: theme.palette.primary.main,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.topBar,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.topBar,
        }
      : {}),
  },
  logo: {
    fontSize: 0,
    width: '80px',
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  toolbar: {
    minHeight: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default styles
