import { useContext } from 'react'

import BadgeContext from 'contexts/BadgeContext'

const useBadge = () => {
  const context = useContext(BadgeContext)

  if (context === undefined) {
    throw new Error('useBadge must be used within a BadgeContext.Provider')
  }

  return {
    badgePartnerServiceWaitingApproved:
      context.badgePartnerServiceWaitingApproved,
    setBadgePartnerServiceWaitingApproved:
      context.setBadgePartnerServiceWaitingApproved,
    badgePartnerWaitingApproved: context.badgePartnerWaitingApproved,
    setBadgePartnerWaitingApproved: context.setBadgePartnerWaitingApproved,
  }
}

export default useBadge
