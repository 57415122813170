import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import LogsTable from './components/LogsTable'

const LogStatusDialog = ({ logs, open, setOpen = () => {} }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Histórico de alterações</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!logs ? (
          <Typography>
            Não existe nenhum histórico de alterações de status para este
            registro.
          </Typography>
        ) : (
          <LogsTable logs={logs} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LogStatusDialog.prototype = {
  logs: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

export default LogStatusDialog
