import { useState } from 'react'

import { Card, Tabs, Box, Tab, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import constants from 'constants/index'

import {
  PartnerServicesTable,
  PartnerAddressesTable,
  PartnerContactsTable,
} from '../'

const PartnerTablesCard = ({
  contacts,
  addresses,
  services,
  reviewPage = false,
}) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true,
  })

  const [currentTab, setCurrentTab] = useState(
    reviewPage ? 'ADDRESSES' : 'SERVICES',
  )
  const [currentTable, setCurrentTable] = useState(
    reviewPage ? (
      <PartnerAddressesTable addresses={addresses} />
    ) : (
      <PartnerServicesTable services={services} />
    ),
  )

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    if (value === 'SERVICES' && !reviewPage) {
      setCurrentTable(<PartnerServicesTable services={services} />)
    }

    if (value === 'ADDRESSES') {
      setCurrentTable(
        <PartnerAddressesTable addresses={addresses} fullscreen={isMobile} />,
      )
    }

    if (value === 'CONTACTS') {
      setCurrentTable(
        <PartnerContactsTable contacts={contacts} fullscreen={isMobile} />,
      )
    }
  }

  return (
    <>
      <Tabs
        scrollButtons="on"
        onChange={handleChangeTabs}
        textColor="primary"
        indicatorColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {reviewPage
          ? constants.partner.DETAIL_REVIEW_TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={<Box>{tab.label}</Box>}
              />
            ))
          : constants.partner.DETAIL_TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={<Box>{tab.label}</Box>}
              />
            ))}
      </Tabs>
      <Card>
        <Box mt={1}>{currentTable}</Box>
      </Card>
    </>
  )
}

export default PartnerTablesCard
