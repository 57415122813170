import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ partnerId = '', ...params }) => {
  return await marketPlaceAPI.get(`/partners/${partnerId}`, { params })
}

const getWaitingApproval = async ({ ...params }) => {
  return await marketPlaceAPI.get(`/partners/waiting_approval`, { params })
}

const changeStatus = async ({
  partnerId = '',
  status = '',
  observations = '',
}) => {
  return await marketPlaceAPI.put(`partners/${partnerId}/change_status`, {
    status,
    observations,
  })
}

const user = {
  get,
  getWaitingApproval,
  changeStatus,
}

export default user
