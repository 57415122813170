const convertStringToCep = (cep) => {
  if (cep.length <= 5) {
    return cep
  }
  if (cep.length >= 9) {
    return cep.slice(0, 9).replace(/^(\d{5})(\d{3}).*/, '$1-$2')
  }

  if (cep.length === 6) {
    return cep.replace(/^(\d{5})(\d{1}).*/, '$1-$2')
  }

  return cep.replace(/^(\d{5})(\d{3}).*/, '$1-$2')
}

export default convertStringToCep
