const paginateArray = (array, perPage) => {
  const length = array?.length || 0
  var arrayCount = 0
  var whileCount = 0
  var minSlice = 0
  var maxSlice = 0

  var slicePoints = []

  while (arrayCount < length) {
    minSlice = whileCount * perPage
    maxSlice =
      (whileCount + 1) * perPage <= length ? (whileCount + 1) * perPage : length
    arrayCount += perPage
    whileCount++
    slicePoints.push([minSlice, maxSlice])
  }
  return slicePoints
}

export default paginateArray
