import { Card, Box, Typography, Divider, Grid } from '@material-ui/core'
import { Label } from 'components'

import helpers from 'helpers'

import useStyles from './styles'

const DetailsPartnerCard = ({ partner }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <Box>
        <Box my={2} ml={2}>
          <Typography variant="h5">Informações sobre o parceiro</Typography>
        </Box>
        <Divider />
        <Box px={3} py={2}>
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Label title="Nome" titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {partner.name}
                </Typography>
              </Label>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Label title="Nome Fantasia" titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {partner.tradeName}
                </Typography>
              </Label>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Label title={partner.documentType} titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {partner.documentType === 'CPF'
                    ? helpers.formatters.cpf(partner.document)
                    : helpers.formatters.cnpj(partner.document)}
                </Typography>
              </Label>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Label title="Data do aceite dos termos de uso" titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {helpers.formatters.date(partner?.termsAcceptedAt) ||
                    'Não aceito'}
                </Typography>
              </Label>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Label title="Visualizações" titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {partner?.views}
                </Typography>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Descrição" titleVariant="h6">
                <Typography variant="body1" color="secondary" align="justify">
                  {partner.description}
                </Typography>
              </Label>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}

export default DetailsPartnerCard
