import chipStatusColor from './chipStatusColor'
import statusName from './statusName'
import changeStatusButtonLabel from './changeStatusButtonLabel'

const companyServices = {
  chipStatusColor,
  statusName,
  changeStatusButtonLabel,
}

export default companyServices
