import { useState, useEffect } from 'react'

import { Box, Chip, TablePagination, useMediaQuery } from '@material-ui/core'

import qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'

import helpers from 'helpers'

import { useTheme } from '@material-ui/styles'

import useStyles from './styles'

const TableRowService = ({ service, ...rest }) => {
  const classes = useStyles()

  return (
    <TableRow size="large" {...rest}>
      <TableCell overFlowSize="92px">{service.id}</TableCell>
      <TableCell overFlowSize="276px">{service.companyService.name}</TableCell>
      <TableCell overFlowSize="276px">{service.description}</TableCell>
      <TableCell overFlowSize="276px">R$ {service.price.toFixed(2)}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={helpers.partners.statusColor(
            service.companyService.status,
            classes,
          )}
          label={helpers.partners.statusLabel(service.companyService.status)}
        />
      </TableCell>
    </TableRow>
  )
}

const PartnerServicesTable = ({ services }) => {
  const [perPage, setPerPage] = useState(3)

  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const search = qs.parse(location.search)

  const setPageServices = () => {
    return (parseInt(search.pageServices) || 1) - 1
  }

  const length = services.length

  const slices = helpers.functions.paginateArray(services, perPage)

  const [query, setQuery] = useState({ pageServices: 1, ...search })

  const verifySearchParams = () => {
    if (parseInt(search.pageServices) <= 0) {
      return 0
    } else {
      return setPageServices()
    }
  }

  const pageServices = verifySearchParams()

  const [serviceList, setContactList] = useState(
    length > 0 && parseInt(search.pageServices) >= 0 && slices[pageServices]
      ? services?.slice(slices[pageServices][0], slices[pageServices][1])
      : [],
  )

  const handleChangePage = (event, newPage) => {
    setQuery({ ...search, pageServices: newPage + 1 })
  }

  const handleChangeRowsPerPage = (event, value) => {
    if (slices[verifySearchParams()]) setQuery({ ...search, pageContacts: 1 })
    setPerPage(value.props.value)
  }

  useEffect(() => {
    const pageIndex = verifySearchParams()

    history.replace(location.pathname + '?' + qs.stringify(query))

    if (length > 0 && pageIndex >= 0 && slices[pageIndex]) {
      setContactList(
        services?.slice(slices[pageIndex][0], slices[pageIndex][1]),
      )
    }
    // eslint-disable-next-line
  }, [search.pageServices, query])

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table size="medium" emptyMessage="Nenhum serviço encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Identificador</TableCell>
              <TableCell width="30%">Nome</TableCell>
              <TableCell width="30%">Descrição</TableCell>
              <TableCell width="15%">Preço</TableCell>
              <TableCell width="15%">Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {serviceList?.map((service) => (
              <TableRowService service={service} key={service.id} hover />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={query.pageServices - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[3, 6, 9, 12]}
        labelRowsPerPage={isDesktop ? 'Por página' : ''}
        nextIconButtonProps={{ size: 'small' }}
        backIconButtonProps={{ size: 'small' }}
      />
    </Box>
  )
}

export default PartnerServicesTable
