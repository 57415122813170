import { Box, Card, Typography } from '@material-ui/core'
import { Container, ContentHeader, Page } from 'components'
import { RedirectLinkForm } from './components'

import useStyles from './styles'

const RedirectLinksNew = () => {
  const classes = useStyles()

  return (
    <Page title="Novo link de redirecionamento">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Links de redirecionamento"
          subtitle="Novo"
        />
        <Card>
          <Box p={2} className={classes.borderBottom}>
            <Typography variant="h5">Criar Link de Redirecionamento</Typography>
          </Box>
          <Box mt={3}>
            <RedirectLinkForm />
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default RedirectLinksNew
