export const getBaseURL = (project) => envsBaseUrl[project][env]

const env = process.env.REACT_APP_API || 'development'

const companyToken = {
  development: '65ebf34ee87c6793c9de9fc3505728fd',
  sandbox: 'a3bdb3abdd1e3f61afa3a853f6d20974',
  production: 'ce66c0754ce3f5543b61a4a7d70b86d1',
}

export const COMPANY_TOKEN_LOGIN_WITH_ID = companyToken[env]

const envsBaseUrl = {
  marketPlace: {
    development: 'http://api.backoffice.localhost:3012',
    sandbox: 'https://api.backoffice.parceiros.sandbox.dponet.com.br',
    production: 'https://api.backoffice.parceiros.dponet.com.br',
  },
  auth: {
    development: 'https://auth.sandbox.oneid.com.br/auth.js',
    sandbox: 'https://auth.sandbox.oneid.com.br/auth.js',
    production: 'https://auth.oneid.com.br/auth.js',
  },
  oneId: {
    development: 'https://app.sandbox.oneid.com.br',
    sandbox: 'https://app.sandbox.oneid.com.br',
    production: 'https://app.oneid.com.br',
  },
}
