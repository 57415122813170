import theme from './theme'
import landingPage from './landingPage'
import partner from './partner'
import companyService from './companyService'
import permissions from './permissions'
import partnerService from './partnerService'
import redirectLink from './redirectLink'
import serviceCategory from './serviceCategory'
import order from './order'

const constants = {
  theme,
  landingPage,
  partner,
  companyService,
  permissions,
  partnerService,
  redirectLink,
  serviceCategory,
  order,
}

export default constants
