import React from 'react'

import {
  Button,
  AppBar,
  Toolbar,
  Box,
  Container,
  Typography,
  Link,
  Divider,
} from '@material-ui/core'

import useStyles from './styles'

import { LogIn } from 'react-feather'

import Logo from 'images/dponet-logo-white.svg'

const TopBar = ({ loginWithButton = () => {}, signUpButton = () => {} }) => {
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar} elevation={2}>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Box className={classes.root}>
            <img src={Logo} alt="logo-horizontal" className={classes.logo} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box mr={2}>
                <Link
                  onClick={() => signUpButton()}
                  className={classes.textButton}
                >
                  <Typography variant="h6">Cadastre-se</Typography>
                </Link>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box ml={2}>
                <Button
                  variant="contained"
                  onClick={() => loginWithButton()}
                  size="small"
                  className={classes.button}
                  startIcon={<LogIn size="16" />}
                >
                  Acessar
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
