import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { LoadingBox, SuccessButton } from 'components'
import { Box, Button, Divider, Grid, TextField } from '@material-ui/core'

import { Save as SaveIcon } from 'react-feather'

import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'

const ServiceCategoryForm = ({ ...props }) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: props?.serviceCategory?.name || '',
      description: props?.serviceCategory?.description || '',
    },
  })

  const onSubmit = (serviceCategory) => {
    setLoading(true)
    if (props.serviceCategory) {
      handleEdit(serviceCategory)
    } else {
      handleCreate(serviceCategory)
    }
  }

  const handleCreate = async (data) => {
    try {
      await service.marketPlace.serviceCategory.create(data)
      setLoading(false)
      snackbar.open({
        message: 'Categoria de Serviço criado com sucesso',
        variant: 'success',
      })
      history.push(routes.serviceCategories.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleEdit = async (data) => {
    try {
      await service.marketPlace.serviceCategory.put({
        serviceCategoryId: props?.serviceCategory?.id,
        serviceCategory: data,
      })
      setLoading(false)
      snackbar.open({
        message: 'Categoria de Serviço atualizado com sucesso',
        variant: 'success',
      })
      history.push(routes.serviceCategories.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" px={3} pb={2}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      label="Nome"
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.description}
                      helperText={errors?.description?.message}
                      rows={8}
                      label="Descrição"
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                Cancelar
              </Button>
            </Box>
            <SuccessButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvar
            </SuccessButton>
          </Box>
        </form>
      )}
    </>
  )
}

export default ServiceCategoryForm
