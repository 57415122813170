const PARTNERS = {
  CREATE: 'create_partners',
  UPDATE: 'update_partners',
  LOGO: 'logo_partners',
  LIST: 'list_partners',
  SHOW: 'show_partners',
  CHANGE_STATUS: 'change_status_partners',
}

const COMPANY_SERVICES = {
  CREATE: 'create_company_services',
  UPDATE: 'update_company_services',
  LIST: 'list_company_services',
  DELETE: 'delete_company_services',
  SHOW: 'show_company_services',
}

const PARTNER_SERVICES = {
  LIST: 'list_partner_services',
  LIST_WAITING_APPROVAL: 'list_partner_services_waiting_approval',
  SHOW: 'show_partner_services',
  CREATE: 'create_partner_services',
  UPDATE: 'update_partner_services',
  DELETE: 'delete_partner_services',
  CHANGE_STATUS: 'change_status_partner_services',
}

const REDIRECT_LINKS = {
  LIST: 'list_redirect_links',
  SHOW: 'show_redirect_links',
  CREATE: 'create_redirect_links',
  UPDATE: 'update_redirect_links',
}

const SERVICE_CATEGORIES = {
  LIST: 'list_service_categories',
  SHOW: 'show_service_categories',
  CREATE: 'create_service_categories',
  UPDATE: 'update_service_categories',
  CHANGE_STATUS: 'change_status_service_categories',
}

const permissions = {
  PARTNERS,
  COMPANY_SERVICES,
  PARTNER_SERVICES,
  REDIRECT_LINKS,
  SERVICE_CATEGORIES,
}

export default permissions
