import useFetch from 'hooks/useFetch'

import { Box, Card, Divider, Typography } from '@material-ui/core'
import { Container, ContentHeader, LoadingBox, Page } from 'components'
import { RedirectLinkForm } from './components'

import * as service from 'service'

const RedirectLinksEdit = ({ match }) => {
  const { redirectLinkId } = match.params

  const { response, isLoading } = useFetch(
    service.marketPlace.redirectLink.get,
    {
      redirectLinkId,
    },
    [redirectLinkId],
  )

  return (
    <Page title="Alterar link de redirecionamento">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Links de redirecionamento"
          subtitle="Alterar"
        />
        <Card>
          <Box p={2}>
            <Typography variant="h5">
              Alterar link de redirecionamento
            </Typography>
          </Box>
          <Divider />
          <Box mt={3}>
            {isLoading ? (
              <LoadingBox />
            ) : (
              <RedirectLinkForm redirectLink={response?.data?.redirectLink} />
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default RedirectLinksEdit
