import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipActive: {
    backgroundColor: colors.green[100],
    borderRadius: '4px',
    color: colors.green[800],
    fontWeight: 700,
    height: '21px',
  },
  chipInactive: {
    backgroundColor: colors.grey[100],
    borderRadius: '4px',
    color: colors.grey[800],
    fontWeight: 700,
    height: '21px',
  },
  chipWaitingApproval: {
    backgroundColor: colors.orange[100],
    borderRadius: '4px',
    color: colors.orange[800],
    fontWeight: 700,
    height: '21px',
  },
  chipReproved: {
    backgroundColor: colors.red[100],
    borderRadius: '4px',
    color: colors.red[800],
    fontWeight: 700,
    height: '21px',
  },
  chipDisabled: {
    backgroundColor: colors.blue[100],
    borderRadius: '4px',
    color: colors.blue[800],
    fontWeight: 700,
    height: '21px',
  },
}))
export default styles
