import { makeStyles } from '@material-ui/core'
import { colors } from '@material-ui/core'
import palette from 'theme/palette'
const styles = makeStyles(() => ({
  successButton: {
    backgroundColor: colors.green[400],
    color: palette.white,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: colors.green[500],
    },
  },
}))

export default styles
