import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ serviceCategoryId = '', ...params }) => {
  return await marketPlaceAPI.get(`/service_categories/${serviceCategoryId}`, {
    params,
  })
}

const create = async (data) => {
  return await marketPlaceAPI.post('/service_categories', data)
}

const put = async ({ serviceCategoryId, ...data }) => {
  return await marketPlaceAPI.put(
    `/service_categories/${serviceCategoryId}`,
    data,
  )
}

const show = async ({ serviceCategoryId }) => {
  return await marketPlaceAPI.get(`/service_categories/${serviceCategoryId}`)
}

const changeStatus = async ({ serviceCategoryId }) => {
  return await marketPlaceAPI.put(
    `/service_categories/${serviceCategoryId}/change_status`,
  )
}

const serviceCategory = {
  get,
  show,
  create,
  put,
  changeStatus,
}

export default serviceCategory
