import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ redirectLinkId = '', ...params }) => {
  return await marketPlaceAPI.get(`/redirect_links/${redirectLinkId}`, {
    params,
  })
}

const create = async (data) => {
  return await marketPlaceAPI.post('/redirect_links', data)
}

const put = async ({ redirectLinkId, ...data }) => {
  return await marketPlaceAPI.put(`/redirect_links/${redirectLinkId}`, data)
}

const redirectLink = {
  get,
  create,
  put,
}

export default redirectLink
