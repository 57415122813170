import useFetch from 'hooks/useFetch'

import { Box, Card, Divider, Typography } from '@material-ui/core'
import { Container, ContentHeader, LoadingBox, Page } from 'components'
import { ServiceCategoryForm } from './components'

import * as service from 'service'

const ServiceCategoriesEdit = ({ match }) => {
  const { serviceCategoryId } = match.params

  const { response, isLoading } = useFetch(
    service.marketPlace.serviceCategory.get,
    {
      serviceCategoryId,
    },
  )

  return (
    <Page title="Edição da categoria de serviço">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Categoria de serviço"
          subtitle="Edição da categoria de serviço"
        />
        <Card>
          <Box p={2}>
            <Typography variant="h5">Editar categoria de serviço</Typography>
          </Box>
          <Divider />
          <Box mt={3}>
            {isLoading ? (
              <LoadingBox />
            ) : (
              <ServiceCategoryForm
                serviceCategory={response?.data?.serviceCategory}
              />
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default ServiceCategoriesEdit
