import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  requirements: yup.string().required(),
  permissions: yup.string().nullable(),
  serviceCategoryId: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
})

export default schema
