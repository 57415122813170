import { Box, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const TableRowOrder = ({ order, currentTab, ...rest }) => {
  const classes = useStyles()

  return (
    <TableRow {...rest}>
      <TableCell>{order?.id}</TableCell>
      <TableCell>{order?.partnerService?.companyService?.name}</TableCell>
      <TableCell>{order?.contractor?.name || 'Não informado'}</TableCell>
      <TableCell>
        {order?.partnerService?.partner?.name || 'Não informado'}
      </TableCell>
      <TableCell>{helpers.formatters.date(order?.createdAt)}</TableCell>
      {currentTab === constants.order.status.ALL_STATUS && (
        <TableCell>
          <Chip
            size="small"
            label={helpers.orders.statusLabel(order?.status)}
            className={helpers.orders.chipStatusColor(order?.status, classes)}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

const OrdersTable = ({ orders, currentTab }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhuma solicitação de serviço encontrada"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="10%">Id</TableCell>
              <TableCell width="30%" align="left">
                Serviço
              </TableCell>
              <TableCell width="30%" align="left">
                Solicitante
              </TableCell>
              <TableCell width="25%" align="left">
                Parceiro
              </TableCell>
              <TableCell width="5%" align="right">
                Data
              </TableCell>
              {currentTab === constants.order.status.ALL_STATUS && (
                <TableCell width="15%">Status</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order) => (
              <TableRowOrder
                order={order}
                key={order.id}
                currentTab={currentTab}
                hover
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default OrdersTable
