import constants from 'constants/index'

const statusName = (status) => {
  switch (status) {
    case constants.partner.status.ACTIVE_STATUS:
      return 'ATIVO'
    case constants.partner.status.INACTIVE_STATUS:
      return 'INATIVO'
    case constants.partner.status.ONBOARD_STATUS:
      return 'ONBOARD'
    case constants.partner.status.WAITING_APPROVAL_STATUS:
      return 'AGUARDANDO APROVAÇÃO'
    case constants.partner.status.REPROVED_STATUS:
      return 'REPROVADO'
    case constants.partner.status.DISABLED_STATUS:
      return 'DESABILITADO'
    default:
      return '-'
  }
}

export default statusName
