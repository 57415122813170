import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import { useSnackbar, useFetch } from 'hooks'

import { LoadingBox, SuccessButton } from 'components'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Save as SaveIcon } from 'react-feather'

import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'

const CompanyServicesForm = ({ companyServiceId, ...props }) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: props?.companyService?.name || '',
      description: props?.companyService?.description || '',
      permissions: props?.companyService?.permissions || '',
      requirements: props?.companyService?.requirements || '',
      serviceCategoryId: {
        id: props?.companyService?.serviceCategory?.id || 0,
        name: props?.companyService?.serviceCategory?.name || '',
      },
    },
  })

  const { response } = useFetch(
    service.marketPlace.serviceCategory.get,
    { status: constants.serviceCategory.ACTIVE_STATUS },
    [],
  )

  const onSubmit = (companyService) => {
    setLoading(true)
    if (props.companyService) {
      handleEdit(companyService)
    } else {
      handleCreate(companyService)
    }
  }

  const handleCreate = async (data) => {
    try {
      await service.marketPlace.companyService.create({
        companyService: {
          ...data,
          serviceCategoryId: data?.serviceCategoryId?.id,
        },
      })
      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa criado com sucesso',
        variant: 'success',
      })
      history.push(routes.companyServices.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleEdit = async (data) => {
    try {
      await service.marketPlace.companyService.put({
        companyServiceId,
        companyService: {
          ...data,
          serviceCategoryId: data?.serviceCategoryId?.id,
        },
      })
      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa atualizado com sucesso',
        variant: 'success',
      })
      history.push(routes.companyServices.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" px={3} pb={10}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      label="Nome"
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.description}
                      helperText={errors?.description?.message}
                      rows={8}
                      label="Descrição"
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.requirements}
                      helperText={errors?.requirements?.message}
                      rows={8}
                      label="Exigências específicas para este serviço"
                    />
                  }
                  control={control}
                  name="requirements"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="caption" color="secondary">
                    Selecione uma categoria
                  </Typography>
                  <Controller
                    control={control}
                    name="serviceCategoryId"
                    onChange={([, data]) => data}
                    mode="onBlur"
                    as={
                      <Autocomplete
                        options={response?.data?.serviceCategories || []}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) =>
                          option?.id === value?.id || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.serviceCategoryId}
                            helperText={
                              errors?.serviceCategoryId
                                ? 'Selecione uma empresa'
                                : ''
                            }
                            variant="outlined"
                          />
                        )}
                      />
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.permissions}
                      helperText={errors?.permissions?.message}
                      rows={3}
                      label="Permissões"
                    />
                  }
                  control={control}
                  name="permissions"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  Para cadastrar as permissões, separe as palavras por vírgula.
                  Ex: create_data_process, edit_data_process, etc...
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                Cancelar
              </Button>
            </Box>
            <SuccessButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvar
            </SuccessButton>
          </Box>
        </form>
      )}
    </>
  )
}

export default CompanyServicesForm
