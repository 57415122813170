import Main from './RedirectLinksMain'
import New from './RedirectLinksNew'
import Edit from './RedirectLinksEdit'

const redirectLinks = {
  Main,
  New,
  Edit,
}

export default redirectLinks
