import { useState, useEffect } from 'react'

import {
  Box,
  Chip,
  TablePagination,
  useMediaQuery,
  Button,
} from '@material-ui/core'

import qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import { DetailsContactDialog } from './components'
import { MenuButton } from 'components'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'

import useStyles from './styles'

import helpers from 'helpers'

import { useTheme } from '@material-ui/styles'

const TableRowContact = ({ contact, fullScreen, ...rest }) => {
  const classes = useStyles()

  const [detailsContactDialog, setDetailsContactDialog] = useState(false)

  return (
    <TableRow {...rest}>
      <TableCell>{contact.id}</TableCell>
      <TableCell overFlowSize="150px">{contact.email}</TableCell>
      <TableCell>{helpers.formatters.phone(contact.phone)}</TableCell>
      <TableCell overFlowSize="250px">{contact.description}</TableCell>
      <TableCell disableTooltip>
        {contact.main === true ? (
          <Chip size="small" className={classes.chipMain} label="Principal" />
        ) : (
          <Chip
            size="small"
            className={classes.chipSecondary}
            label="Secundário"
          />
        )}
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setDetailsContactDialog(true)
            }}
          >
            Detalhes
          </Button>
        </MenuButton>
      </TableCell>
      <DetailsContactDialog
        contact={contact}
        open={detailsContactDialog}
        setOpen={setDetailsContactDialog}
        fullScreen={fullScreen}
      />
    </TableRow>
  )
}

const PartnerContactsTable = ({ contacts, fullScreen = false }) => {
  const [perPage, setPerPage] = useState(3)

  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const search = qs.parse(location.search)

  const setPageContacts = () => {
    return (parseInt(search.pageContacts) || 1) - 1
  }

  const length = contacts.length

  const slices = helpers.functions.paginateArray(contacts, perPage)

  const [query, setQuery] = useState({ pageContacts: 1, ...search })

  const verifySearchParams = () => {
    if (parseInt(search.pageContacts) <= 0) {
      return 0
    } else {
      return setPageContacts()
    }
  }

  const pageContacts = verifySearchParams()

  const [contactList, setContactList] = useState(
    length > 0 && parseInt(search.pageContacts) >= 0 && slices[pageContacts]
      ? contacts?.slice(slices[pageContacts][0], slices[pageContacts][1])
      : [],
  )

  const handleChangePage = (event, newPage) => {
    setQuery({ ...search, pageContacts: newPage + 1 })
  }

  const handleChangeRowsPerPage = (event, value) => {
    if (slices[verifySearchParams()]) setQuery({ ...search, pageContacts: 1 })
    setPerPage(value.props.value)
  }

  useEffect(() => {
    const pageIndex = verifySearchParams()

    history.replace(location.pathname + '?' + qs.stringify(query))

    if (length > 0 && pageIndex >= 0 && slices[pageIndex]) {
      setContactList(
        contacts?.slice(slices[pageIndex][0], slices[pageIndex][1]),
      )
    }
    // eslint-disable-next-line
  }, [search.pageContacts, query])

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table size="small" emptyMessage="Nenhum contato encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Identificador</TableCell>
              <TableCell width="15%">Email</TableCell>
              <TableCell width="15%">Telefone</TableCell>
              <TableCell width="30%">Descrição</TableCell>
              <TableCell width="20%">Tipo</TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactList?.map((contact) => (
              <TableRowContact
                contact={contact}
                key={contact.id}
                fullScreen={fullScreen}
                hover
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={query.pageContacts - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[3, 6, 9, 12]}
        labelRowsPerPage={isDesktop ? 'Por página' : ''}
        nextIconButtonProps={{ size: 'small' }}
        backIconButtonProps={{ size: 'small' }}
      />
    </Box>
  )
}

export default PartnerContactsTable
