const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'
const ONBOARD_STATUS = 'ONBOARD'
const WAITING_APPROVAL_STATUS = 'WAITING_APPROVAL'
const DISABLED_STATUS = 'DISABLED'
const REPROVED_STATUS = 'REPROVED'

const REPROVE_DIALOG_INFO_MESSAGE =
  'Tem certeza que deseja reprovar este parceiro?'

const ACTIVATE_DIALOG_INFO_MESSAGE =
  'Tem certeza que deseja ativar este parceiro?'

const DISABLE_DIALOG_INFO_MESSAGE =
  'Tem certeza que deseja desativar este parceiro?'

const DETAIL_TABS = [
  {
    value: 'SERVICES',
    label: 'Serviço(s)',
  },
  {
    value: 'ADDRESSES',
    label: 'Endereço(s)',
  },
  {
    value: 'CONTACTS',
    label: 'Contato(s)',
  },
]

const DETAIL_REVIEW_TABS = [
  {
    value: 'ADDRESSES',
    label: 'Endereço(s)',
  },
  {
    value: 'CONTACTS',
    label: 'Contato(s)',
  },
]

const TABS = [
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
  {
    value: ONBOARD_STATUS,
    label: 'On Board',
  },
  {
    value: DISABLED_STATUS,
    label: 'Desativados',
  },
  {
    value: REPROVED_STATUS,
    label: 'Reprovados',
  },
]

const status = {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  ONBOARD_STATUS,
  WAITING_APPROVAL_STATUS,
  DISABLED_STATUS,
  REPROVED_STATUS,
}

const partner = {
  TABS,
  status,
  DETAIL_TABS,
  DETAIL_REVIEW_TABS,
  REPROVE_DIALOG_INFO_MESSAGE,
  ACTIVATE_DIALOG_INFO_MESSAGE,
  DISABLE_DIALOG_INFO_MESSAGE,
}

export default partner
