import { Chip, Grid, Typography } from '@material-ui/core'
import { Label } from 'components'

import useStyles from './styles'
import helpers from 'helpers'

const CompanyServiceDetails = ({ companyService }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Label title="Nome do serviço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {companyService.name}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label title="Categoria" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {companyService?.serviceCategory?.name}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label title="Descrição" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {companyService.description}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} xl={4}>
        <Label title="Status" titleVariant="h5">
          <Chip
            size="small"
            className={helpers.companyServices.chipStatusColor(
              companyService.status,
              classes,
            )}
            label={helpers.companyServices.statusName(companyService.status)}
          />
        </Label>
      </Grid>
      <Grid item xs={12}>
        <Label
          title="Exigências específicas para este serviço"
          titleVariant="h5"
        >
          <Typography variant="body1" color="secondary" align="justify">
            {companyService.requirements}
          </Typography>
        </Label>
      </Grid>
    </Grid>
  )
}

export default CompanyServiceDetails
