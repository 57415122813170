import { Box, Card, Typography } from '@material-ui/core'
import { Container, ContentHeader, Page } from 'components'
import { CompanyServicesForm } from './components'

import useStyles from './styles'

const CompanyServicesNew = () => {
  const classes = useStyles()

  return (
    <Page title="Novo serviço de empresa">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Serviços da empresa"
          subtitle="Novo serviço da empresa"
        />
        <Card>
          <Box p={2} className={classes.borderBottom}>
            <Typography variant="h5">Criar serviço da empresa</Typography>
          </Box>
          <Box mt={3}>
            <CompanyServicesForm />
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default CompanyServicesNew
