import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipActive: {
    backgroundColor: colors.green[100],
    borderRadius: '4px',
    color: colors.green[800],
    fontWeight: 700,
    height: '21px',
  },
  chipInactive: {
    backgroundColor: colors.blue[100],
    borderRadius: '4px',
    color: colors.blue[800],
    fontWeight: 700,
    height: '21px',
  },
  tableHeaderHeight: {
    height: theme.spacing(7),
  },
}))

export default styles
