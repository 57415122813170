import React from 'react'
import { useLocation, Router } from 'react-router-dom'
import { getBaseURL } from 'service/env'
import { ThemeProvider } from '@material-ui/styles'
import { withStyles } from '@material-ui/styles'
import { Helmet } from 'react-helmet'

import 'configs/yup/locale'

import theme from 'theme'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { AuthProvider, BadgeProvider } from 'providers'

import Routes from './Routes'
import history from 'service/history'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import 'react-perfect-scrollbar/dist/css/styles.css'

const isTestEnv = ['development', 'sandbox'].includes(process.env.REACT_APP_API)

const App = () => (
  <ThemeProvider theme={theme}>
    <Helmet
      script={[
        {
          type: 'text/javascript',
          src: getBaseURL('auth'),
          name: 'leavening_login_with',
          'data-name': 'env',
          env: isTestEnv ? 'sandbox' : process.env.REACT_APP_API,
        },
      ]}
    />

    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
      <Router history={history}>
        <ScrollToTop />
        <BadgeProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </BadgeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
)

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
