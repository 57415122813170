import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { LoadingBox, SuccessButton } from 'components'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Save as SaveIcon } from 'react-feather'

import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const RedirectLinkForm = ({ ...props }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState(
    props?.redirectLink?.filters?.['categoriesIds'] || [],
  )
  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      pageName: props?.redirectLink?.pageName || '',
      pageUri: props?.redirectLink?.pageUri || '',
      filters: props?.redirectLink?.filters || '',
      status:
        props?.redirectLink?.status || constants.redirectLink.ACTIVE_STATUS,
    },
  })

  const { response, isLoading } = useFetch(
    service.marketPlace.companyService.get,
    {
      status: constants.companyService.ACTIVE_STATUS,
      perPage: 10000,
    },
  )

  const onSubmit = (redirectLink) => {
    redirectLink.filters = { categoriesIds: selectedCategories }
    setLoading(true)
    if (props.redirectLink) {
      handleEdit(redirectLink)
    } else {
      handleCreate(redirectLink)
    }
  }

  const handleCreate = async (data) => {
    try {
      await service.marketPlace.redirectLink.create({ redirectLink: data })
      setLoading(false)
      snackbar.open({
        message: 'Link de redirecionamento criado com sucesso',
        variant: 'success',
      })
      history.push(routes.redirectLinks.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleEdit = async (data) => {
    try {
      await service.marketPlace.redirectLink.put({
        redirectLinkId: props?.redirectLink?.id,
        redirectLink: data,
      })
      setLoading(false)
      snackbar.open({
        message: 'Link de redirecionamento atualizado com sucesso',
        variant: 'success',
      })
      history.push(routes.redirectLinks.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  const handleCategory = (event) => {
    const categoryToken = event.target.value
    if (categoryIsChecked(categoryToken)) {
      removeCategory(categoryToken)
    } else {
      setSelectedCategories([...selectedCategories, event.target.value])
    }
  }

  const categoryIsChecked = (categoryToken) => {
    return selectedCategories.includes(categoryToken)
  }

  const removeCategory = (categoryToken) => {
    var aux = [...selectedCategories]
    const index = aux.indexOf(categoryToken)
    aux.splice(index, 1)
    setSelectedCategories(aux)
  }

  return (
    <>
      {loading || isLoading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" px={3} pb={10}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.pageName}
                      helperText={errors?.pageName?.message}
                      label="Nome da página"
                    />
                  }
                  control={control}
                  name="pageName"
                  mode="onBlur"
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.pageUri}
                      helperText={errors?.pageUri?.message}
                      label="URI da página"
                    />
                  }
                  control={control}
                  name="pageUri"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>URI da página</AlertTitle>
                  <Typography variant="body1" color="secondary" gutterBottom>
                    Os links de redirecionamento cadastrados serão exibidos no
                    portal de seus clientes. Para incluir uma novo link de
                    recionamentro, preencha o<strong> "URI da página"</strong>{' '}
                    com a<strong> URI</strong>" da página desejada.
                  </Typography>
                  <Typography variant="body1" color="secondary" gutterBottom>
                    Ex:
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    <strong>1. Listagem de processos</strong>
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    URL: https://dponet.sandbox.immunizesystem.com
                    <strong>/data-processes</strong>
                  </Typography>
                  <Typography variant="body1" color="secondary" gutterBottom>
                    URI que deverá ser preenchida:
                    <strong>/data-processes</strong>
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    <strong>2. Visualizar processos</strong>
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    URL: https://dponet.sandbox.immunizesystem.com
                    <strong>/data-processes/show/4000</strong>
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    URI que deverá ser preenchida:
                    <strong>/data-processes/show/:id</strong>
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Status
                </Typography>
                <Typography variant="body2" color="secondary">
                  Links de redirecionamento com status inativo não podem ser
                  utilizados pelos clientes.
                </Typography>
                <Controller
                  control={control}
                  name="status"
                  defaultValue={
                    props?.redirectLink?.status ||
                    constants.redirectLink.ACTIVE_STATUS
                  }
                  as={
                    <RadioGroup row color="primary">
                      <FormControlLabel
                        value={constants.redirectLink.ACTIVE_STATUS}
                        control={<Radio color="primary" />}
                        label="Ativo"
                      />
                      <FormControlLabel
                        value={constants.redirectLink.INACTIVE_STATUS}
                        control={<Radio color="primary" />}
                        label="Inativo"
                      />
                    </RadioGroup>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Filtros
                </Typography>
                <Typography variant="body2" color="secondary" gutterBottom>
                  Selecione as categorias de serviços que você deseja que sejam
                  filtradas quando o usuário clicar no botão de ajuda.
                </Typography>
                <FormGroup>
                  {response?.data?.companyServices?.map((companyService) => (
                    <FormControlLabel
                      className={classes.ellipsis}
                      key={companyService?.id}
                      control={
                        <Checkbox
                          checked={categoryIsChecked(companyService?.token)}
                          value={companyService?.token}
                          onChange={handleCategory}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2" color="secondary">
                          {companyService?.name}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                Cancelar
              </Button>
            </Box>
            <SuccessButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvar
            </SuccessButton>
          </Box>
        </form>
      )}
    </>
  )
}

export default RedirectLinkForm
