import { useState } from 'react'

import { useHistory } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Page,
  ContentHeader,
  FilterButton,
  Permitted,
  LoadingBox,
  Filters,
  Container,
} from 'components'
import { RedirectLinksTable } from './components'
import {
  Button,
  Tabs,
  Tab,
  Card,
  Box,
  Grid,
  useMediaQuery,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'
import { Plus as PlusIcon } from 'react-feather'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'

const RedirectLinksMain = () => {
  const classes = useStyles()
  const history = useHistory()
  const filter = useFilter()
  const theme = useTheme()

  const [currentTab, setCurrentTab] = useState('ACTIVE')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.marketPlace.redirectLink.get,
    {
      ...{ ...filter.filters },
      status: currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters, currentTab],
  )

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page title="Links de redirecionamento">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Gerenciamento"
          title="Links de redirecionamento"
          subtitle="Listagem"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box my={2}>
          <Tabs
            scrollButtons="on"
            onChange={handleChangeTabs}
            textColor="primary"
            indicatorColor="primary"
            value={currentTab}
            className={classes.borderBottom}
            variant="scrollable"
          >
            {constants.redirectLink.TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={<Box>{tab.label}</Box>}
              />
            ))}
          </Tabs>
          <Box mt={3}>
            <Card>
              <Box
                display="flex"
                width="100%"
                p={0}
                className={classes.borderBottom}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      width="100%"
                      p={2}
                      height="100%"
                      display="flex"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        Links de redirecionamento
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box p={2} display="flex" justifyContent="flex-end">
                      <Permitted
                        tag={constants.permissions.REDIRECT_LINKS.CREATE}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<PlusIcon />}
                          onClick={() => history.push(routes.redirectLinks.new)}
                        >
                          Criar
                        </Button>
                      </Permitted>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {isLoading ? (
                <LoadingBox />
              ) : (
                <>
                  <RedirectLinksTable
                    redirectLinks={response?.data?.redirectLinks}
                  />
                  <Box px={2} display="flex" justifyContent="flex-end">
                    <TablePagination
                      component="div"
                      count={response.data.meta.totalCount}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page - 1}
                      rowsPerPage={perPage}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      labelRowsPerPage={isDesktop ? 'Por página' : ''}
                      nextIconButtonProps={{ size: 'small' }}
                      backIconButtonProps={{ size: 'small' }}
                    />
                  </Box>
                </>
              )}
            </Card>
          </Box>
          <Filters filter={filter}>
            <input textfieldinput="true" label="Identificador" name="id" />
            <input
              textfieldinput="true"
              label="Nome da página"
              name="pageName"
            />
            <input textfieldinput="true" label="URI da página" name="pageUri" />
          </Filters>
        </Box>
      </Container>
    </Page>
  )
}

export default RedirectLinksMain
