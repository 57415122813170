import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ companyServiceId = '', ...params }) => {
  return await marketPlaceAPI.get(`/company_services/${companyServiceId}`, {
    params,
  })
}

const create = async (data) => {
  return await marketPlaceAPI.post('/company_services', data)
}

const put = async ({ companyServiceId, ...data }) => {
  return await marketPlaceAPI.put(`/company_services/${companyServiceId}`, data)
}

const destroy = async ({ companyServiceId }) => {
  return await marketPlaceAPI.delete(`/company_services/${companyServiceId}`)
}

const partnersService = async ({ companyServiceId, ...params }) => {
  return await marketPlaceAPI.get(
    `/company_services/${companyServiceId}/partners_service`,
    {
      params,
    },
  )
}

const companyService = {
  get,
  create,
  put,
  partnersService,
  destroy,
}

export default companyService
