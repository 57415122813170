const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'

const TABS = [
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
]

const redirectLink = {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  TABS,
}

export default redirectLink
