const ANY_STATUS = ''
const INACTIVE_STATUS = 'INACTIVE'
const ACTIVE_STATUS = 'ACTIVE'

const MESSAGE_CHANGE_STATUS = 'Deseja inativar esta categoria de serviço?'

const TABS = [
  {
    value: ANY_STATUS,
    label: 'Todos',
  },
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
]

const companyService = {
  ANY_STATUS,
  INACTIVE_STATUS,
  ACTIVE_STATUS,
  MESSAGE_CHANGE_STATUS,
  TABS,
}

export default companyService
