import { Box, Button, Chip } from '@material-ui/core'

import { reverse } from 'named-urls'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { MenuButton } from 'components'

import PerfectScrollbar from 'react-perfect-scrollbar'

import useStyles from './styles'

import helpers from 'helpers'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

const TableRowRedirectLink = ({ redirectLink, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const navigateToShow = (redirectLinkId) => {
    history.push(
      reverse(routes.redirectLinks.edit, {
        redirectLinkId: redirectLinkId,
      }),
    )
  }

  return (
    <TableRow {...rest}>
      <TableCell>{redirectLink?.id}</TableCell>
      <TableCell>{redirectLink?.pageName}</TableCell>
      <TableCell>{redirectLink?.pageUri}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={helpers.redirectLinks.chipStatusColor(
            redirectLink.status,
            classes,
          )}
          label={helpers.redirectLinks.statusLabel(redirectLink.status)}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              navigateToShow(redirectLink.id)
            }}
          >
            Editar
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const RedirectLinksTable = ({ redirectLinks }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhum link de redirecionamento encontrado"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="10%">Id</TableCell>
              <TableCell width="30%" align="left">
                Nome da página
              </TableCell>
              <TableCell width="30%" align="left">
                URI da página
              </TableCell>
              <TableCell width="25%" align="left">
                Status
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {redirectLinks?.map((redirectLink) => (
              <TableRowRedirectLink
                redirectLink={redirectLink}
                key={redirectLink.id}
                hover
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default RedirectLinksTable
