import { useEffect, useState } from 'react'

import {
  Container,
  ContentHeader,
  LoadingBox,
  MenuButton,
  Page,
  Permitted,
  LogStatusDialog,
  BaseActionDialog as ReproveStatusDialog,
} from 'components'

import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  TextField,
} from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'

import {
  PartnerServiceDetails,
  ProvidedServicesTable,
  ReviewPartnerService,
  DocumentsTable,
  schema,
} from './components'

import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'
import helpers from 'helpers'
import { useSnackbar } from 'hooks'

const CompanyServicesShow = ({ match }) => {
  const classes = useStyles()

  const [partnerService, setPartnerService] = useState()
  const [logStateOpen, setLogStateOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [canChangeTo, setCanChangeTo] = useState([])
  const [openReproveDialog, setOpenReproveDialog] = useState(false)
  const [reload, setReload] = useState(0)
  const [currentTab, setCurrentTab] = useState('documents')
  const { control, handleSubmit, errors, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      observations: '',
    },
  })

  const { partnerServiceId, partnerId } = match.params
  const snackbar = useSnackbar()

  const handleChangeStatus = async (status) => {
    try {
      await service.marketPlace.partnerService.changeStatus({
        partnerId,
        partnerServiceId,
        status,
      })
      setReload(reload + 1)
      snackbar.open({
        message: 'Status do serviço do parceiro foi alterado com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleReprove = async () => {
    try {
      await service.marketPlace.partnerService.changeStatus({
        partnerId,
        partnerServiceId,
        status: constants.partnerService.REPROVED_STATUS,
        observations: getValues(['observations']),
      })
      setReload(reload + 1)
      setOpenReproveDialog(false)
      snackbar.open({
        message: 'Serviço do parceiro foi reprovado com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
      setOpenReproveDialog(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    const defineChangeTo = async (response) => {
      setCanChangeTo(
        helpers.partnerServices.changeStatus.canChangeTo(response?.status),
      )
    }
    const loadPartnerService = async () => {
      let response = await service.marketPlace.partnerService.get({
        partnerId,
        partnerServiceId,
      })
      setPartnerService(response?.data?.partnerService)
      return response?.data?.partnerService
    }
    loadPartnerService()
      .then((response) => {
        defineChangeTo(response)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [reload])

  const partnerServiceObservation = (observation) => {
    // eslint-disable-next-line
    return observation?.replace(`{\"observations\"=>\"`, '').replace('"}', '')
  }

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page title="Detalhes do serviço do parceiro">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Serviços do parceiros"
          title="Visualizar"
          subtitle="Serviços do parceiro"
        >
          <Permitted
            someTags={[
              constants.permissions.PARTNER_SERVICES.CHANGE_STATUS,
              constants.permissions.PARTNER_SERVICES.LIST,
              constants.permissions.PARTNER_SERVICES.LIST_WAITING_APPROVAL,
            ]}
          >
            <MenuButton>
              <Permitted
                tag={constants.permissions.PARTNER_SERVICES.CHANGE_STATUS}
              >
                {canChangeTo.includes(constants.partnerService.ACTIVE_STATUS) &&
                  partnerService.status !==
                    constants.partnerService.WAITING_APPROVAL_STATUS && (
                    <Button
                      onClick={() =>
                        handleChangeStatus(
                          constants.partnerService.ACTIVE_STATUS,
                        )
                      }
                    >
                      Ativar
                    </Button>
                  )}
                {canChangeTo.includes(
                  constants.partnerService.DISABLED_STATUS,
                ) && (
                  <Button
                    onClick={() =>
                      handleChangeStatus(
                        constants.partnerService.DISABLED_STATUS,
                      )
                    }
                  >
                    Desativar
                  </Button>
                )}
              </Permitted>
              <Button onClick={() => setLogStateOpen(true)}>Histórico</Button>
            </MenuButton>
          </Permitted>
        </ContentHeader>
        <>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <>
              {partnerService.status ===
                constants.partnerService.REPROVED_STATUS && (
                <Box my={2}>
                  <Alert severity="error">
                    <AlertTitle>Motivo da reprova</AlertTitle>
                    {partnerServiceObservation(
                      partnerService?.partnerServiceStatusInteractions[
                        partnerService?.partnerServiceStatusInteractions
                          .length - 1
                      ].observations,
                    )}
                  </Alert>
                </Box>
              )}
              {partnerService.status ===
                constants.partnerService.WAITING_APPROVAL_STATUS && (
                <Box width="100%" mb={2}>
                  <Card>
                    <ReviewPartnerService
                      handleReprove={() => setOpenReproveDialog(true)}
                      handleApprove={() =>
                        handleChangeStatus(
                          constants.partnerService.ACTIVE_STATUS,
                        )
                      }
                    />
                  </Card>
                </Box>
              )}
              <Card>
                <Box p={2}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="h5">Informações</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box mt={1} p={2}>
                  <PartnerServiceDetails partnerService={partnerService} />
                </Box>
              </Card>
              <Box mt={4}>
                <Tabs
                  scrollButtons="on"
                  textColor="primary"
                  indicatorColor="primary"
                  value={currentTab}
                  onChange={handleChangeTabs}
                  TabIndicatorProps={{ width: 'auto' }}
                  variant="scrollable"
                >
                  {helpers.partnerServices
                    .mountTabs(partnerService?.status)
                    .map((tab) => (
                      <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                        className={classes.tabWidth}
                      />
                    ))}
                </Tabs>
                <Card>
                  {currentTab === 'documents' ? (
                    <DocumentsTable documents={partnerService?.documents} />
                  ) : (
                    <ProvidedServicesTable
                      providedServices={partnerService.providedServices}
                    />
                  )}
                </Card>
              </Box>
            </>
          )}
        </>
      </Container>
      <LogStatusDialog
        logs={partnerService?.partnerServiceStatusInteractions}
        open={logStateOpen}
        setOpen={setLogStateOpen}
      />
      <ReproveStatusDialog
        title="Reprovar serviço"
        infoMessage={constants.partnerService.REPROVE_DIALOG_INFO_MESSAGE}
        actionButtonText="Reprovar"
        maxWidth="sm"
        open={openReproveDialog}
        setOpen={setOpenReproveDialog}
        action={handleReprove}
      >
        <form onSubmit={handleSubmit(handleReprove)}>
          <Box width="100%" pb={2}>
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.observations}
                  helperText={errors?.name?.observations}
                  label="Observação"
                />
              }
              control={control}
              name="observations"
              mode="onBlur"
            />
          </Box>
        </form>
      </ReproveStatusDialog>
    </Page>
  )
}

export default CompanyServicesShow
