import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.redirectLink.ACTIVE_STATUS:
      return classes.chipActive
    case constants.redirectLink.INACTIVE_STATUS:
      return classes.chipInactive
    default:
      return null
  }
}

export default chipStatusColor
