import auth from './auth'
import user from './user'
import partner from './partner'
import companyService from './companyService'
import partnerService from './partnerService'
import redirectLink from './redirectLink'
import serviceCategory from './serviceCategory'
import order from './order'

const marketPlace = {
  auth,
  user,
  partner,
  companyService,
  partnerService,
  redirectLink,
  serviceCategory,
  order,
}

export default marketPlace
