import statusName from './statusName'
import chipStatusColor from './chipStatusColor'
import statusLabel from './statusLabel'
import statusColor from './statusColor'

const partners = {
  statusName,
  chipStatusColor,
  statusLabel,
  statusColor,
}

export default partners
