import { Chip, Grid, Typography } from '@material-ui/core'
import { Label } from 'components'

import useStyles from './styles'
import helpers from 'helpers'

const PartnerServiceDetails = ({ partnerService }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Label title="Nome do serviço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService?.companyService?.name}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
        <Label title="Data de criação" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {helpers.formatters.date(partnerService?.createdAt)}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
        <Label title="Status" titleVariant="h5">
          <Chip
            size="small"
            className={helpers.partnerServices.chipStatusColor(
              partnerService.status,
              classes,
            )}
            label={helpers.partnerServices.statusName(partnerService.status)}
          />
        </Label>
      </Grid>
      <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
        <Label title="Nome do parceiro" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService?.partner.name}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Label title="Descrição" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            {partnerService?.description}
          </Typography>
        </Label>
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <Label title="Preço" titleVariant="h5">
          <Typography variant="body1" color="secondary" align="justify">
            R$ {partnerService.price.toFixed(2)}
          </Typography>
        </Label>
      </Grid>
    </Grid>
  )
}

export default PartnerServiceDetails
