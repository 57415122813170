import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.partner.status.ACTIVE_STATUS:
      return classes.chipActive
    case constants.partner.status.INACTIVE_STATUS:
      return classes.chipInactive
    case constants.partner.status.ONBOARD_STATUS:
      return classes.chipOnBoard
    case constants.partner.status.WAITING_APPROVAL_STATUS:
      return classes.chipWaitingApproval
    case constants.partner.status.REPROVED_STATUS:
      return classes.chipReproved
    case constants.partner.status.DISABLED_STATUS:
      return classes.chipDisabled
    default:
      return null
  }
}

export default chipStatusColor
