import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Forbidden = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <Box className={classes.content}>
            <Typography variant="h1">403: Não autorizado</Typography>
            <Typography variant="subtitle2">
              Você tentou algum caminho desconhecido ou veio aqui por engano.
              Seja qual for, tente usar a navegação
            </Typography>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push('/')}
              >
                Voltar ao inicio
              </Button>
            </Box>
            <img
              alt="Em desenvolvimento"
              className={classes.image}
              src="/images/forbidden.svg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Forbidden
