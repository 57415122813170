import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { routes } from 'Routes'
import helpers from 'helpers'

const TableRowPartnersService = ({ partnerService, ...rest }) => {
  const partner = partnerService?.partner

  return (
    <TableRow
      {...rest}
      cursor="pointer"
      to={reverse(routes.partners.show, {
        partnerId: partner.id,
      })}
    >
      <TableCell>{partner.id}</TableCell>
      <TableCell>{partner.name}</TableCell>
      <TableCell>
        {partner.documentType === 'CPF'
          ? helpers.formatters.cpf(partner.document)
          : helpers.formatters.cnpj(partner.document)}
      </TableCell>
    </TableRow>
  )
}

const PartnersServiceTable = ({ partnersService }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhum parceiro relacionado a este serviço foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow height="55px">
              <TableCell width="20%">Identificador</TableCell>
              <TableCell width="40%">Nome</TableCell>
              <TableCell width="40%">Documento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnersService?.map((partnerService) => (
              <TableRowPartnersService
                partnerService={partnerService}
                hover
                height="50px"
                key={partnerService.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default PartnersServiceTable
