import { useContext } from 'react'

import AuthContext from 'contexts/AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext.Provider')
  }

  return {
    auth: context.auth,
    setAuth: context.setAuth,
    isLoading: context.isLoading,
    loaded: context.loaded,
    setUser: context.setUser,
    user: context.user,
    permissions: context.permissions,
    menuItems: context.menuItems,
    loadData: context.loadData,
    loadDataWithAnimation: context.loadDataWithAnimation,
  }
}

export default useAuth
