import { useState } from 'react'

import {
  Page,
  ContentHeader,
  FilterButton,
  Filters,
  Container,
} from 'components'

import {
  Tabs,
  Tab,
  Card,
  Box,
  CircularProgress,
  useMediaQuery,
  TablePagination,
  Typography,
} from '@material-ui/core'

import constants from 'constants/index'

import { PartnersContentList } from './components'

import * as service from 'service'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { useTheme } from '@material-ui/styles'

const PartnersMain = () => {
  const [currentTab, setCurrentTab] = useState('ACTIVE')

  const filter = useFilter()
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.marketPlace.partner.get,
    {
      ...{ ...filter.filters },
      status: currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters, currentTab],
  )
  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page title="Parceiros">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader menu="Parceiros" title="Listagem" subtitle="Parceiros">
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box my={2}>
          <Tabs
            scrollButtons="on"
            onChange={handleChangeTabs}
            textColor="primary"
            indicatorColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {constants.partner.TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={<Box>{tab.label}</Box>}
              />
            ))}
          </Tabs>
          <Card>
            <Box my={2} ml={2}>
              <Typography variant="h5">Parceiros</Typography>
            </Box>
            {isLoading ? (
              <Box
                display="flex"
                width="100%"
                minHeight="700px"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <PartnersContentList partners={response?.data?.partners} />
                <Box px={2} display="flex" justifyContent="flex-end">
                  <TablePagination
                    component="div"
                    count={response.data.meta.totalCount}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page - 1}
                    rowsPerPage={perPage}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage={isDesktop ? 'Por página' : ''}
                    nextIconButtonProps={{ size: 'small' }}
                    backIconButtonProps={{ size: 'small' }}
                  />
                </Box>
              </>
            )}
          </Card>
          <Filters filter={filter}>
            <input textfieldinput="true" label="Identificador" name="id" />
            <input textfieldinput="true" label="Nome" name="name" />
            <input
              textfieldinput="true"
              label="Numero do documento"
              name="document"
            />
            <select
              textfieldinput="true"
              label="Tipo do documento"
              name="documentType"
            >
              <option value=""></option>
              <option value="CPF">CPF</option>
              <option value="CNPJ">CNPJ</option>
            </select>
            <input
              datepickerinput="true"
              label="Data de criação inicial"
              name="createdAtGt"
              data-target="createdAtLt"
            />
            <input
              datepickerinput="true"
              label="Data de criação final"
              name="createdAtLt"
              data-target="createdAtGt"
            />
            <select textfieldinput="true" label="Tipo do parceiro" name="kind">
              <option value=""></option>
              <option value="C">Jurídico</option>
              <option value="P">Físico</option>
            </select>
          </Filters>
        </Box>
      </Container>
    </Page>
  )
}

export default PartnersMain
