import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipMain: {
    backgroundColor: colors.green[100],
    borderRadius: '4px',
    color: colors.green[800],
    fontWeight: 700,
    height: '21px',
  },
  chipSecondary: {
    backgroundColor: colors.orange[100],
    borderRadius: '4px',
    color: colors.orange[800],
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
