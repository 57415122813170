import { colors } from '@material-ui/core'

const styles = (theme) => ({
  logo: {
    width: '75px',
    height: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linkButton: {
    color: colors.lightBlue[500],
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  linkText: {
    color: colors.lightBlue[500],
    fontWeight: 700,
  },
  textMargin: {
    marginLeft: '5px',
  },
  helpBox: {
    backgroundColor: colors.grey[200],
  },
})

export default styles
