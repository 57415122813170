import axios from 'axios'
import humps from 'humps'
import qs from 'qs'
import { getBaseURL } from './env'
import * as service from './index'

import history from 'service/history'
import { routes } from 'Routes'

const create = (projectTag, treatError = true) => {
  let api = axios.create({ baseURL: getBaseURL(projectTag) })

  api.interceptors.request.use((reqConfig) => {
    const authToken = service.marketPlace.auth.getToken()
    if (authToken) {
      reqConfig.headers.Authorization = `${authToken}`
    }

    reqConfig.headers.crossDomain = true
    reqConfig.data = humps.decamelizeKeys(reqConfig.data)
    reqConfig.params = humps.decamelizeKeys(reqConfig.params)

    reqConfig.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    return reqConfig
  })

  api.interceptors.response.use(
    (resp) => humps.camelizeKeys(resp),
    (error) => {
      if (treatError) {
        if (error?.response?.status === 404) {
          if (error?.params?.listAnswersOrigin) {
            history.push(routes.notFound)
            return Promise.reject(humps.camelizeKeys(error))
          }
        }

        if (error && error.response && error.response.status === 403) {
          history.push(routes.forbidden)
          return Promise.reject(humps.camelizeKeys(error))
        }
      }
      return Promise.reject(humps.camelizeKeys(error))
    },
  )

  return api
}

const api = {
  create,
}

export default api
