import { useState, useEffect } from 'react'

import {
  Grid,
  Box,
  CircularProgress,
  Button,
  TextField,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'

import {
  Page,
  ContentHeader,
  Permitted,
  Container,
  MenuButton,
  LogStatusDialog,
  BaseActionDialog as DisableStatusDialog,
  BaseActionDialog as ActivateStatusDialog,
} from 'components'

import {
  DetailsPartnerCard,
  PartnerCard,
  PartnerTablesCard,
} from './components'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import { reverse } from 'named-urls'

import * as service from 'service'

import constants from 'constants/index'

import { Alert, AlertTitle } from '@material-ui/lab'

import schema from './components/schema'

const PartnersShow = ({ match }) => {
  const { partnerId } = match.params

  const { control, handleSubmit, errors, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      observations: '',
    },
  })

  const [logStateOpen, setLogStateOpen] = useState(false)
  const [openDisableDialog, setOpenDisableDialog] = useState(false)
  const [openActivateDialog, setOpenActivateDialog] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [reload, setReload] = useState(0)
  const [partner, setPartner] = useState()

  useEffect(() => {
    setLoading(true)

    const loadPartner = async () => {
      let response = await service.marketPlace.partner.get({
        partnerId,
      })
      return response?.data?.partner
    }
    loadPartner().then((partner) => {
      setPartner(partner)

      setLoading(false)
    })
    // eslint-disable-next-line
  }, [reload])

  const history = useHistory()

  const handleActivate = async () => {
    await service.marketPlace.partner.changeStatus({
      partnerId,
      status: 'ACTIVE',
    })
    setOpenActivateDialog(false)
    setReload(reload + 1)
  }

  const handleDisable = async () => {
    await service.marketPlace.partner.changeStatus({
      partnerId,
      status: constants.partner.status.DISABLED_STATUS,
      observations: getValues(['observations']),
    })
    setOpenDisableDialog(false)
    setReload(reload + 1)
  }

  const navigateToReview = () => {
    history.push(
      reverse(routes.partners.review, {
        partnerId,
      }),
    )
  }

  const partnerObservation = (observation) => {
    // eslint-disable-next-line
    return observation?.replace(`{\"observations\"=>\"`, '').replace('"}', '')
  }

  return (
    <Page title="Parceiros">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        {isLoading ? (
          <Box
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ContentHeader
              menu="Parceiros"
              title="Visualizar"
              subtitle="Parceiro"
            >
              <MenuButton>
                <Permitted tag={constants.permissions.PARTNERS.CHANGE_STATUS}>
                  {partner.status ===
                    constants.partner.status.WAITING_APPROVAL_STATUS && (
                    <Button onClick={() => navigateToReview()}>Analisar</Button>
                  )}
                  {partner.status ===
                    constants.partner.status.DISABLED_STATUS && (
                    <Button onClick={() => setOpenActivateDialog(true)}>
                      Ativar
                    </Button>
                  )}
                  {partner.status ===
                    constants.partner.status.ACTIVE_STATUS && (
                    <Button onClick={() => setOpenDisableDialog(true)}>
                      Desativar
                    </Button>
                  )}
                </Permitted>
                <Button onClick={() => setLogStateOpen(true)}>
                  Histórico de alterações
                </Button>
              </MenuButton>
            </ContentHeader>
            <Grid container spacing={2}>
              {[
                constants.partner.status.REPROVED_STATUS,
                constants.partner.status.DISABLED_STATUS,
              ].includes(partner.status) && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    <AlertTitle>
                      Motivo da{' '}
                      {constants.partner.status.REPROVED_STATUS ===
                      partner.status
                        ? 'reprova'
                        : 'desabilitação'}
                    </AlertTitle>
                    {partnerObservation(
                      partner.partnerStatusInteractions[
                        partner.partnerStatusInteractions.length - 1
                      ].observations,
                    )}
                  </Alert>
                </Grid>
              )}
              <Grid item lg={4} md={4} xs={12}>
                <PartnerCard partner={partner} />
              </Grid>
              <Grid item lg={8} md={8} xs={12}>
                <DetailsPartnerCard partner={partner} />
              </Grid>
              <Grid item xs={12}>
                <PartnerTablesCard
                  services={partner.partnerServices}
                  contacts={partner.contacts}
                  addresses={partner.addresses}
                />
              </Grid>
            </Grid>
            <LogStatusDialog
              logs={partner.partnerStatusInteractions}
              open={logStateOpen}
              setOpen={setLogStateOpen}
            />
            <ActivateStatusDialog
              title="Ativar parceiro"
              infoMessage={constants.partner.ACTIVATE_DIALOG_INFO_MESSAGE}
              actionButtonText="Ativar"
              maxWidth="sm"
              open={openActivateDialog}
              setOpen={setOpenActivateDialog}
              action={handleActivate}
            />

            <DisableStatusDialog
              title="Desabilitar parceiro"
              infoMessage={constants.partner.DISABLE_DIALOG_INFO_MESSAGE}
              actionButtonText="Desabilitar"
              maxWidth="sm"
              open={openDisableDialog}
              setOpen={setOpenDisableDialog}
              action={handleDisable}
            >
              <form onSubmit={handleSubmit(handleDisable)}>
                <Box width="100%" pb={2}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        error={!!errors.observations}
                        helperText={errors?.name?.observations}
                        label="Observação"
                      />
                    }
                    control={control}
                    name="observations"
                    mode="onBlur"
                  />
                </Box>
              </form>
            </DisableStatusDialog>
          </>
        )}
      </Container>
    </Page>
  )
}

export default PartnersShow
