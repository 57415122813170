import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  HelpDialog,
  Page,
  Permitted,
} from 'components'
import { CompanyServicesTable } from './components'

import { Plus as PlusIcon } from 'react-feather'

import constants from 'constants/index'
import * as service from 'service'

import useStyles from './styles'
import { routes } from 'Routes'

const CompanyServicesMain = () => {
  const classes = useStyles()
  const history = useHistory()
  const filter = useFilter()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [currentTab, setCurrentTab] = useState(
    constants.companyService.ACTIVE_STATUS,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.marketPlace.companyService.get,
    {
      ...{ ...filter.filters },
      status: currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const { response: responseCategories } = useFetch(
    service.marketPlace.serviceCategory.get,
    {
      status: constants.serviceCategory.ACTIVE_STATUS,
    },
    [],
  )

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    filter.setFilters({ ...filter.filters, status: value })
  }

  return (
    <Page title="Todos os serviços da empresa">
      <Container maxWidth={false} spacingXl={30} spacingLg={10}>
        <ContentHeader
          menu="Gerenciamentos"
          title="Serviços da empresa"
          subtitle="Listagem"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Tabs
          scrollButtons="on"
          onChange={handleChangeTabs}
          textColor="primary"
          className={classes.borderBottom}
          indicatorColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {constants.companyService.TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Box mt={3}>
          <Card>
            <Box
              display="flex"
              width="100%"
              p={0}
              className={classes.borderBottom}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Serviços da empresa</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted
                      tag={constants.permissions.COMPANY_SERVICES.CREATE}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={() => history.push(routes.companyServices.new)}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" width="100%">
              <Grid container>
                <CompanyServicesTable
                  companyServices={response?.data?.companyServices}
                  isLoading={isLoading || loading}
                  onEvent={refresh}
                  setLoading={setLoading}
                />
              </Grid>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading || loading ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome" name="name" />
          <input textfieldinput="true" label="Descrição" name="description" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
          <select
            autocompleteselectinput="true"
            name="serviceCategoryId"
            label="Categoria"
            optionvalues={responseCategories?.data?.serviceCategories}
          />
        </Filters>
      </Container>
      <HelpDialog
        title={constants.companyService.HELP_TITLE}
        about={constants.companyService.HELP_ABOUT}
        helpText={constants.companyService.HELP_TEXT}
      />
    </Page>
  )
}

export default CompanyServicesMain
