import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.secondary.superLight}`,
  },
  tabWidth: {
    width: 'auto',
    maxWidth: '100%',
  },
  buttonApprove: {
    backgroundColor: '#4CAF50',
    marginRight: theme.spacing(2),
  },
}))

export default styles
