import { useState } from 'react'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  HelpDialog,
  Page,
} from 'components'
import { PartnerServicesTable } from './components'

import constants from 'constants/index'
import * as service from 'service'

import useStyles from './styles'

const PartnerServicesMain = () => {
  const classes = useStyles()
  const filter = useFilter()
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [currentTab, setCurrentTab] = useState(
    constants.companyService.ACTIVE_STATUS,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.marketPlace.partnerService.get,
    {
      ...{ ...filter.filters },
      status: currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
    filter.setFilters({ ...filter.filters, status: value })
  }

  return (
    <Page title="Todos os serviços dos parceiros">
      <Container maxWidth={false} spacingXl={30} spacingLg={10}>
        <ContentHeader
          menu="Serviços dos parceiros "
          title="Todos"
          subtitle="Listagem"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Tabs
          scrollButtons="on"
          onChange={handleChangeTabs}
          textColor="primary"
          className={classes.borderBottom}
          indicatorColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {constants.partnerService.TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Box mt={3}>
          <Card>
            <Box
              display="flex"
              width="100%"
              p={0}
              className={classes.borderBottom}
            >
              <Box
                width="100%"
                p={2}
                height="100%"
                display="flex"
                alignItems="center"
              >
                <Typography variant="h5">Serviços dos parceiros</Typography>
              </Box>
            </Box>
            <Box display="flex" width="100%">
              <Grid container>
                <PartnerServicesTable
                  partnerServices={response?.data?.partnerServices}
                  isLoading={isLoading || loading}
                  onEvent={refresh}
                  setLoading={setLoading}
                />
              </Grid>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading || loading ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Nome do parceiro"
            name="partner_name"
          />
          <input
            textfieldinput="true"
            label="Nome do serviço"
            name="company_service_name"
          />
          <input textfieldinput="true" label="Descrição" name="description" />
          <input price="true" label="Preço Mínimo" name="priceGt" />
          <input price="true" label="Preço Máximo" name="priceLt" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
        </Filters>
      </Container>
      <HelpDialog
        title={constants.partnerService.HELP_TITLE}
        about={constants.partnerService.HELP_ABOUT}
        helpText={constants.partnerService.HELP_TEXT_ALL}
      />
    </Page>
  )
}

export default PartnerServicesMain
