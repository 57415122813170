import { Box, Typography, Button, Card } from '@material-ui/core'

import constants from 'constants/index'

import useStyles from './styles'

function PartnerReviewCard({
  handleApprove = () => {},
  handleReprove = () => {},
}) {
  const classes = useStyles()

  return (
    <Card>
      <Box m={2}>
        <Typography variant="h5">
          Deseja aprovar ou reprovar este parceiro?
        </Typography>
        <Box mt={1}>
          <Typography variant="body1">
            No caso do cadastro do parceiro ser reprovado, só será possível
            revisar novamente quando o parceiro enviar seu cadastro para
            análise.
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            color="primary"
            className={classes.buttonApprove}
            variant="contained"
            onClick={() =>
              handleApprove(constants.partner.status.ACTIVE_STATUS)
            }
          >
            Aprovar
          </Button>
          <Button color="secondary" variant="contained" onClick={handleReprove}>
            Reprovar
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default PartnerReviewCard
