import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ partnerId = '', partnerServiceId = '', ...params }) => {
  if (partnerId !== '') {
    return await marketPlaceAPI.get(
      `partner/${partnerId}/partner_services/${partnerServiceId}`,
    )
  }
  return await marketPlaceAPI.get(`/partner_services/${partnerServiceId}`, {
    params,
  })
}

const getWaitingApproval = async ({ ...params }) => {
  return await marketPlaceAPI.get('/partner_services/waiting_approval', {
    params,
  })
}

const changeStatus = async ({
  partnerId = '',
  partnerServiceId = '',
  status = '',
  observations = '',
}) => {
  return await marketPlaceAPI.put(
    `partner/${partnerId}/partner_services/${partnerServiceId}/change_status`,
    {
      status,
      observations,
    },
  )
}

const partnerService = {
  get,
  getWaitingApproval,
  changeStatus,
}

export default partnerService
