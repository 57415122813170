import constants from 'constants/index'

const statusColor = (status, classes) => {
  switch (status) {
    case constants.partner.status.ACTIVE_STATUS:
      return classes.chipActive
    case constants.partner.status.INACTIVE_STATUS:
      return classes.chipInactive
    case constants.partner.WAITING_APPROVAL_STATUS:
      return classes.chipWaitingApproval
    case constants.partner.REPROVED_STATUS:
      return classes.chipReproved
    case constants.partner.DISABLED_STATUS:
      return classes.chipDisabled
    default:
      return null
  }
}

export default statusColor
