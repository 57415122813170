import constants from 'constants/index'

const changeStatusButtonLabel = (status) => {
  switch (status) {
    case constants.companyService.ACTIVE_STATUS:
      return 'Inativar'
    case constants.companyService.INACTIVE_STATUS:
      return 'Ativar'
    default:
      return ''
  }
}

export default changeStatusButtonLabel
