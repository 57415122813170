import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation, matchPath } from 'react-router'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Divider,
  Hidden,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'

import NavItem from '../NavItem'

import DialogDesk360 from 'components/DialogDesk360'

import { Permitted } from 'components'

import useAuth from 'hooks/useAuth'

import Logo from 'images/dponet-logo-white.svg'

import styles from './styles'

const useStyles = makeStyles(styles)

const renderNavItems = ({ items, ...rest }) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  )
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  menuDisabled,
  depth = 0,
}) => {
  const key = (item.id || item.title) + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        show={item.show}
        menuDisabled={menuDisabled}
        countToBadge={item.countToBadge}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          menuDisabled: menuDisabled,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        show={item.show}
        title={item.title}
        menuDisabled={menuDisabled}
        countToBadge={item.countToBadge}
      />,
    )
  }

  return acc
}

const Content = () => {
  const auth = useAuth()
  const location = useLocation()
  const classes = useStyles()
  const [dialogDesk360, setDialogDesk360] = useState(false)

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={3} display="flex" justifyContent="center">
            <RouterLink to="/">
              <img src={Logo} alt="logo" width="60px" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box mt={4} mb={4} textAlign="center">
          <Typography variant="h5" color="textPrimary" underline="none">
            {auth?.user?.name}
          </Typography>
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {auth?.account?.name === 'empty_account'
                ? ''
                : auth?.account?.name}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="80vh"
        >
          <Box p={2}>
            {auth.menuItems &&
              auth.menuItems.map((item) => (
                <Permitted
                  tag={item.permission}
                  someTags={item.somePermissions}
                  key={item.subheader}
                  show={item.show}
                >
                  <List
                    key={item.subheader}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {item.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: item.items,
                      pathname: location.pathname,
                    })}
                  </List>
                </Permitted>
              ))}
          </Box>
          <Box>
            <Divider />
            <Box p={2}>
              <Box
                p={2}
                mt={1}
                borderRadius="borderRadius"
                className={classes.helpBox}
              >
                <Typography variant="h6" className={classes.textMargin}>
                  Atendimento
                </Typography>

                <Button
                  onClick={() => setDialogDesk360(true)}
                  variant="text"
                  size="small"
                  className={classes.linkButton}
                  component="a"
                >
                  <Typography variant="subtitle2" className={classes.linkText}>
                    Entre em contato
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </PerfectScrollbar>
      <DialogDesk360 open={dialogDesk360} setOpen={setDialogDesk360} />
    </Box>
  )
}

export default Content
