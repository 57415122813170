import { useState, useEffect } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, TablePagination, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingBox } from 'components'

import helpers from 'helpers'

const TableRowProvidedServices = ({
  providedService,
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  return (
    <>
      <TableRow {...rest}>
        <TableCell>{providedService.id}</TableCell>
        <TableCell overFlowSize="200px">
          {providedService.contractor.name}
        </TableCell>
        <TableCell overFlowSize="200px">
          {helpers.formatters.date(providedService.createdAt)}
        </TableCell>
        <TableCell overFlowSize="200px">
          {helpers.formatters.date(providedService.expirationDate)}
        </TableCell>
      </TableRow>
    </>
  )
}

const ProvidedServicesTable = ({
  providedServices,
  isLoading,
  onEvent = () => {},
  setLoading = () => {},
}) => {
  const [perPage, setPerPage] = useState(5)

  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const search = qs.parse(location.search)

  const setPageProvidedServices = () => {
    return (parseInt(search.pageProvidedServices) || 1) - 1
  }

  const length = providedServices.length

  const slices = helpers.functions.paginateArray(providedServices, perPage)

  const [query, setQuery] = useState({ pageProvidedServices: 1, ...search })

  const verifySearchParams = () => {
    if (parseInt(search.pageProvidedServices) <= 0) {
      return 0
    } else {
      return setPageProvidedServices()
    }
  }

  const pageProvidedServices = verifySearchParams()

  const [providedServiceList, setProvidedServicesList] = useState(
    length > 0 &&
      parseInt(search.pageProvidedServices) >= 0 &&
      slices[pageProvidedServices]
      ? providedServices?.slice(
          slices[pageProvidedServices][0],
          slices[pageProvidedServices][1],
        )
      : [],
  )

  const handleChangePage = (event, newPage) => {
    setQuery({ ...search, pageProvidedServices: newPage + 1 })
  }

  const handleChangeRowsPerPage = (event, value) => {
    setQuery({ ...search, pageProvidedServices: 1 })
    if (slices[verifySearchParams()])
      setQuery({ ...search, pageProvidedServices: 1 })
    setPerPage(value.props.value)
  }

  useEffect(() => {
    const pageIndex = verifySearchParams()

    history.replace(location.pathname + '?' + qs.stringify(query))

    if (length > 0 && pageIndex >= 0 && slices[pageIndex]) {
      setProvidedServicesList(
        providedServices?.slice(slices[pageIndex][0], slices[pageIndex][1]),
      )
    }
    // eslint-disable-next-line
  }, [search.pageProvidedServices, query])

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="medium"
              emptyMessage="Nenhum serviço prestado encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="30%">Nome do contratante</TableCell>
                  <TableCell width="25%">Data de criação</TableCell>
                  <TableCell width="25%">Data de expiração</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {providedServiceList?.map((providedService) => (
                  <TableRowProvidedServices
                    providedService={providedService}
                    key={providedService.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={query.pageProvidedServices - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      )}
    </>
  )
}

export default ProvidedServicesTable
