import constants from 'constants/index'

const mountTabs = (status) => {
  if (status === constants.partnerService.WAITING_APPROVAL_STATUS) {
    return [
      {
        value: 'documents',
        label: 'Documentos',
      },
    ]
  } else {
    return [
      {
        value: 'documents',
        label: 'Documentos',
      },
      {
        value: 'providedServices',
        label: 'Serviços prestados',
      },
    ]
  }
}

export default mountTabs
