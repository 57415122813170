import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    width: '132px',
    height: '42px',
    padding: '0px',
  },
  smallLogo: {
    width: '50px',
    height: '40px',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    padding: '0px',
  },
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
  },
  button: {
    background: '#39B4ED',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: '#3198C5',
    },
  },
  textButton: {
    color: theme.palette.primary.contrastText,
  },
}))

export default styles
