const INACTIVE_STATUS = 'INACTIVE'
const ACTIVE_STATUS = 'ACTIVE'
const WAITING_APPROVAL_STATUS = 'WAITING_APPROVAL'
const REPROVED_STATUS = 'REPROVED'
const DISABLED_STATUS = 'DISABLED'

const HELP_ABOUT = 'Sobre os serviços do parceiro'
const HELP_TITLE = 'Como funcionam os serviços do parceiro?'

const HELP_TEXT_ALL =
  'Os serviços do parceiro representam quais atividades seus parceiros estão prestando para sua empresa, desta maneira. ' +
  'É importante ressaltar que os serviços de parceiros possuem cinco status porém nessa página estão sendo listados quatro desses status, ' +
  'Ativo, Inativo, Reprovado e Desativado.'

const HELP_TEXT_WAITING_APPROVAL =
  'Os serviços do parceiro representam quais atividades seus parceiros estão prestando para sua empresa, desta maneira. ' +
  'É importante ressaltar que os serviços de parceiros possuem cinco status porém nessa página estão sendo listado apenas um status, o Aguardando aprovação. ' +
  'Esse status tem uma importância maior para o portal backoffice por ter a necessidade de sua análise, sendo assim ele em particular é exibido separadamente.'

const REPROVE_DIALOG_INFO_MESSAGE =
  'Tem certeza que deseja reprovar este serviço?'

const TABS = [
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
  {
    value: REPROVED_STATUS,
    label: 'Reprovado',
  },
  {
    value: DISABLED_STATUS,
    label: 'Desativado',
  },
]

const constants = {
  INACTIVE_STATUS,
  ACTIVE_STATUS,
  WAITING_APPROVAL_STATUS,
  REPROVED_STATUS,
  DISABLED_STATUS,
  TABS,
  HELP_ABOUT,
  HELP_TITLE,
  HELP_TEXT_ALL,
  HELP_TEXT_WAITING_APPROVAL,
  REPROVE_DIALOG_INFO_MESSAGE,
}

export default constants
