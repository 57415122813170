import chipStatusColor from './chipStatusColor'
import statusName from './statusName'
import statusHistory from './statusHistory'
import changeStatus from './changeStatus'
import formatPrice from './formatPrice'
import mountTabs from './mountTabs'

const partnerServices = {
  chipStatusColor,
  statusName,
  statusHistory,
  changeStatus,
  formatPrice,
  mountTabs,
}

export default partnerServices
