import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ ...params }) => {
  return await marketPlaceAPI.get(`/orders`, {
    params,
  })
}

const order = {
  get,
}

export default order
