import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

import useStyles from './styles'

const LogsTable = ({ logs }) => {
  const classes = useStyles()

  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table size="medium" noWrap stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Identificador</TableCell>
              <TableCell width="25%">Status Antigo</TableCell>
              <TableCell width="25%">Status Novo</TableCell>
              <TableCell width="20%">Data</TableCell>
              <TableCell width="20%">Autor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRow key={log.id}>
                <TableCell width="10%">{log.id}</TableCell>
                <TableCell width="25%" disableTooltip>
                  <Chip
                    size="small"
                    className={helpers.partnerServices.chipStatusColor(
                      helpers.partnerServices.statusHistory.oldStatus(
                        logs,
                        index,
                      ),
                      classes,
                    )}
                    label={helpers.partnerServices.statusName(
                      helpers.partnerServices.statusHistory.oldStatus(
                        logs,
                        index,
                      ),
                    )}
                  />
                </TableCell>
                <TableCell width="25%" disableTooltip>
                  <Chip
                    size="small"
                    className={helpers.partnerServices.chipStatusColor(
                      log.status,
                      classes,
                    )}
                    label={helpers.partnerServices.statusName(log.status)}
                  />
                </TableCell>
                <TableCell width="20%">
                  {helpers.formatters.date(log.createdAt)}{' '}
                  {helpers.formatters.hours(log.createdAt)}
                </TableCell>
                <TableCell width="20%">{log.author.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

LogsTable.prototype = {
  logs: PropTypes.object.isRequired,
}

export default LogsTable
