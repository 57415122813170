import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonApprove: {
    backgroundColor: '#4CAF50',
    marginRight: theme.spacing(2),
  },
}))

export default styles
