import { useState } from 'react'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Button, CircularProgress, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import {
  MenuButton,
  Permitted,
  BaseActionDialog as ChangeStatusDiolog,
} from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import { routes } from 'Routes'
import * as service from 'service'

const TableRowServiceCategory = ({
  serviceCategory,
  setLoading,
  onEvent,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const [changeStatusDiolog, setChangeStatusDiolog] = useState(false)

  const handleEdit = (serviceCategoryId) => {
    history.push(
      reverse(routes.serviceCategories.edit, {
        serviceCategoryId,
      }),
    )
  }

  const handleChangeStatus = async (serviceCategoryId) => {
    try {
      await service.marketPlace.serviceCategory.changeStatus({
        serviceCategoryId: serviceCategoryId,
      })
      onEvent()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{serviceCategory?.id}</TableCell>
        <TableCell>{serviceCategory?.name}</TableCell>
        <TableCell disableTooltip>
          <Chip
            size="small"
            className={helpers.companyServices.chipStatusColor(
              serviceCategory?.status,
              classes,
            )}
            label={helpers.companyServices.statusName(serviceCategory?.status)}
          />
        </TableCell>

        <Permitted
          someTags={[
            constants.permissions.SERVICE_CATEGORIES.UPDATE,
            constants.permissions.SERVICE_CATEGORIES.CHANGE_STATUS,
          ]}
        >
          <TableCell align="right" disableTooltip>
            <MenuButton>
              <Permitted
                tag={constants.permissions.SERVICE_CATEGORIES.CHANGE_STATUS}
              >
                <Button fullWidth onClick={() => setChangeStatusDiolog(true)}>
                  {helpers.formatters.changeStatus(serviceCategory?.status)}
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.SERVICE_CATEGORIES.UPDATE}>
                <Button
                  fullWidth
                  onClick={() => handleEdit(serviceCategory?.id)}
                >
                  Editar
                </Button>
              </Permitted>
            </MenuButton>
          </TableCell>
        </Permitted>
      </TableRow>
      <ChangeStatusDiolog
        title="Excluir serviço"
        infoMessage={constants.serviceCategory.MESSAGE_CHANGE_STATUS}
        actionButtonText="Confirmar"
        maxWidth="sm"
        open={changeStatusDiolog}
        setOpen={setChangeStatusDiolog}
        action={() => handleChangeStatus(serviceCategory?.id)}
      />
    </>
  )
}

const ServiceCategoriesTable = ({
  serviceCategories,
  isLoading,
  onEvent,
  setLoading,
}) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum serviço da empresa encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow className={classes.tableHeaderHeight}>
                  <TableCell width="20%">Identificador</TableCell>
                  <TableCell width="30%">Nome</TableCell>
                  <TableCell width="20%">Status</TableCell>
                  <TableCell width="20%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceCategories?.map((serviceCategory) => (
                  <TableRowServiceCategory
                    serviceCategory={serviceCategory}
                    key={serviceCategory?.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}
ServiceCategoriesTable.propTypes = {
  serviceCategories: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  onEvent: PropTypes.func,
  setLoading: PropTypes.func,
}

TableRowServiceCategory.propTypes = {
  serviceCategory: PropTypes.object,
  onEvent: PropTypes.func,
  setLoading: PropTypes.func,
}

ServiceCategoriesTable.propTypes = {
  onEvent: () => {},
  setLoading: () => {},
}

TableRowServiceCategory.propTypes = {
  onEvent: () => {},
  setLoading: () => {},
}

export default ServiceCategoriesTable
