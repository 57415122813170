import { include } from 'named-urls'

import { Switch, Redirect, Route } from 'react-router-dom'
import { RouteWithLayout } from './components'

import { Main as MainLayout } from './layouts'

import {
  LandingPage,
  NotFound,
  Forbidden,
  Home,
  CompanyServices,
  Partners,
  PartnerServices,
  RedirectLinks,
  ServiceCategories,
  Orders,
} from 'views'

import SnackbarProvider from 'providers/SnackbarProvider'
import constants from 'constants/index'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.root}
        component={LandingPage}
        provider={SnackbarProvider}
        exact
      />
      <RouteWithLayout
        path={routes.home}
        component={Home}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partners.all}
        component={Partners.PartnersMain}
        permission={constants.permissions.PARTNERS.LIST}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyServices.all}
        component={CompanyServices.Main}
        permission={constants.permissions.COMPANY_SERVICES.LIST}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyServices.new}
        component={CompanyServices.New}
        permission={constants.permissions.COMPANY_SERVICES.CREATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyServices.edit}
        component={CompanyServices.Edit}
        permission={constants.permissions.COMPANY_SERVICES.UPDATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyServices.show}
        component={CompanyServices.Show}
        permission={constants.permissions.COMPANY_SERVICES.SHOW}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partners.show}
        component={Partners.PartnersShow}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partners.review}
        component={Partners.PartnersReview}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partners.waitingApproval}
        component={Partners.PartnersWaitingApproval}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partnerServices.all}
        component={PartnerServices.Main}
        permission={constants.permissions.PARTNER_SERVICES.LIST}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partnerServices.waitingApproval}
        component={PartnerServices.WaitingApproval}
        permission={
          constants.permissions.PARTNER_SERVICES.LIST_WAITING_APPROVAL
        }
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.partnerServices.show}
        component={PartnerServices.Show}
        permission={constants.permissions.PARTNER_SERVICES.SHOW}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.redirectLinks.all}
        component={RedirectLinks.Main}
        permission={constants.permissions.REDIRECT_LINKS.LIST}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.redirectLinks.new}
        component={RedirectLinks.New}
        permission={constants.permissions.REDIRECT_LINKS.CREATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.redirectLinks.edit}
        component={RedirectLinks.Edit}
        permission={constants.permissions.REDIRECT_LINKS.UPDATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.serviceCategories.all}
        component={ServiceCategories.Main}
        permission={constants.permissions.SERVICE_CATEGORIES.LIST}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.serviceCategories.new}
        component={ServiceCategories.New}
        permission={constants.permissions.SERVICE_CATEGORIES.CREATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.serviceCategories.edit}
        component={ServiceCategories.Edit}
        permission={constants.permissions.SERVICE_CATEGORIES.UPDATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <RouteWithLayout
        path={routes.orders.all}
        component={Orders.Main}
        permission={constants.permissions.SERVICE_CATEGORIES.UPDATE}
        layout={MainLayout}
        animatedLoading
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',

  home: '/home',

  companyServices: include('/company-services', {
    all: '',
    new: 'new',
    edit: 'edit/:companyServiceId',
    show: 'show/:companyServiceId',
  }),

  notFound: '/not-found',
  forbidden: '/forbidden',

  partners: include('/partners', {
    all: '',
    show: 'show/:partnerId',
    waitingApproval: 'waiting-approval',
    review: 'review/:partnerId',
  }),

  partnerServices: include('/partner-services', {
    all: '',
    waitingApproval: 'waiting-approval',
    show: 'partner/:partnerId/show/:partnerServiceId',
  }),

  redirectLinks: include('/redirect-links', {
    all: '',
    new: 'new',
    edit: 'edit/:redirectLinkId',
  }),

  serviceCategories: include('/service-categories', {
    all: '',
    new: 'new',
    edit: 'edit/:serviceCategoryId',
  }),

  orders: include('/orders', {
    all: '',
  }),
}

export default Routes
