import constants from 'constants/index'

const canChangeTo = (status) => {
  switch (status) {
    case constants.partnerService.WAITING_APPROVAL_STATUS:
      return [
        constants.partnerService.REPROVED_STATUS,
        constants.partnerService.ACTIVE_STATUS,
      ]
    case constants.partnerService.ACTIVE_STATUS:
      return [constants.partnerService.DISABLED_STATUS]
    case constants.partnerService.DISABLED_STATUS:
      return [constants.partnerService.ACTIVE_STATUS]
    default:
      return []
  }
}

const changeStatus = { canChangeTo }

export default changeStatus
