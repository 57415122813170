import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    width: '164px',
    height: '50px !important',
    marginBottom: theme.spacing(3),
  },
  banner: {
    background: "url('/images/banner.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  grayContainer: {
    width: '100%',
    backgroundColor: '#F4F6F8',
  },
  rootBody: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default styles
