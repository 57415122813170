import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: '#969494',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  list: {
    listStyleType: 'none',
    display: 'inline-flex',
  },
  listItem: {
    padding: theme.spacing(0, 3, 2, 3),
  },
}))

export default styles
