const INACTIVE_STATUS = 'INACTIVE'
const ACTIVE_STATUS = 'ACTIVE'
const HELP_ABOUT = 'Sobre os serviços da empresa'
const HELP_TITLE = 'Como funcionam os serviços da empresa?'
const HELP_TEXT =
  'Os serviços da empresa representam quais atividades seus parceiros poderão prestar, desta maneira ' +
  'é possível garantir que os parceiros prestem somente serviços que você permita. É importante ressaltar que os serviços de ' +
  'empresas possuem dois status, Ativo e Inativo. Os parceiros não poderão se cadastrar para prestar serviços que estiverem inativos na plataforma. ' +
  'além de não ser possível inativar um serviço que já existam parceiros que o prestem.'

const TABS = [
  {
    value: ACTIVE_STATUS,
    label: 'Ativos',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
]

const CHANGE_STATUS_DIALOG_TITLES = {
  ACTIVE: 'Inativar serviço',
  INACTIVE: 'Ativar serviço',
}

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVE =
  'Ao inativar um serviço novos parceiros não poderão se credenciar para presta-lo, porém, ' +
  'a inativação não implicará em quaisquer alterações nos parceiros que já são credenciados para prestar este serviço.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVE =
  'Ao reativar um serviço novos parceiros poderão se credenciar para presta-lo, ' +
  'é importante ressaltar que está reativação não impactará nenhum parceiro que já é credenciados para prestar este serviço.'

const CHANGE_STATUS_DIALOG_INFO_MESSAGES = {
  ACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_ACTIVE,
  INACTIVE: CHANGE_STATUS_DIALOG_INFO_MESSAGE_INACTIVE,
}

const CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT = {
  ACTIVE: 'Inativar',
  INACTIVE: 'Ativar',
}

const CHANGE_STATUS_VALUE = {
  ACTIVE: INACTIVE_STATUS,
  INACTIVE: ACTIVE_STATUS,
}

const CHANGE_STATUS_SUCCESS_MESSAGES = {
  ACTIVE: 'Serviço inativado com sucesso!',
  INACTIVE: 'Serviço ativado com sucesso!',
}

const DELETE_DIALOG_INFO_MESSAGE =
  'Tem certeza que deseja excluir este serviço? Esta ação é irreversível.'

const companyService = {
  INACTIVE_STATUS,
  ACTIVE_STATUS,
  TABS,
  HELP_ABOUT,
  HELP_TITLE,
  HELP_TEXT,
  CHANGE_STATUS_DIALOG_TITLES,
  CHANGE_STATUS_DIALOG_INFO_MESSAGES,
  CHANGE_STATUS_DIALOG_ACTION_BUTTON_TEXT,
  CHANGE_STATUS_VALUE,
  CHANGE_STATUS_SUCCESS_MESSAGES,
  DELETE_DIALOG_INFO_MESSAGE,
}

export default companyService
