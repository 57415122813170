import { useState, useEffect } from 'react'
import { reverse } from 'named-urls'
import { Grid, Box, CircularProgress, TextField } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import {
  Page,
  ContentHeader,
  Container,
  BaseActionDialog as ReproveStatusDialog,
} from 'components'

import {
  DetailsPartnerCard,
  PartnerCard,
  PartnerTablesCard,
  PartnerReviewCard,
  schema,
} from './components'

import constants from 'constants/index'

import { useFetch, useSnackbar, useAuth } from 'hooks'

import * as service from 'service'

import { Controller, useForm } from 'react-hook-form'

import helpers from 'helpers'

import { routes } from 'Routes'

const PartnersReview = ({ match }) => {
  const { partnerId } = match.params

  const [openReproveDialog, setOpenReproveDialog] = useState(false)

  const snackbar = useSnackbar()
  const auth = useAuth()
  const { response, isLoading } = useFetch(service.marketPlace.partner.get, {
    partnerId,
  })

  const { control, handleSubmit, errors, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      observations: '',
    },
  })

  const history = useHistory()

  useEffect(() => {
    if (
      !isLoading &&
      constants.partner.status.WAITING_APPROVAL_STATUS !==
        response?.data?.partner?.status
    ) {
      history.push(
        reverse(routes.partners.show, {
          partnerId: partnerId,
        }),
      )
    }
    // eslint-disable-next-line
  }, [isLoading])

  const handleChangeStatus = async (status) => {
    try {
      await service.marketPlace.partner.changeStatus({
        partnerId,
        status,
      })
      auth.loadDataWithAnimation()
      history.push(routes.partners.all)
      snackbar.open({
        message: 'Status do parceiro foi alterado com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  const handleReprove = async () => {
    try {
      await service.marketPlace.partner.changeStatus({
        partnerId,
        status: constants.partner.status.REPROVED_STATUS,
        observations: getValues(['observations']),
      })
      setOpenReproveDialog(false)
      auth.loadDataWithAnimation()
      history.push(routes.partners.all)
      snackbar.open({
        message: 'Parceiro foi reprovado com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
      setOpenReproveDialog(false)
    }
  }

  return (
    <Page title="Parceiros">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Parceiros"
          title="Visualizar"
          subtitle="Parceiro"
        />
        {isLoading ? (
          <Box
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PartnerReviewCard
                handleApprove={() =>
                  handleChangeStatus(constants.partner.status.ACTIVE_STATUS)
                }
                handleReprove={() => setOpenReproveDialog(true)}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <PartnerCard partner={response?.data?.partner} />
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <DetailsPartnerCard partner={response?.data?.partner} />
            </Grid>
            <Grid item xs={12}>
              <PartnerTablesCard
                services={response?.data?.partner?.partnerServices}
                contacts={response?.data?.partner?.contacts}
                addresses={response?.data?.partner?.addresses}
                reviewPage
              />
            </Grid>
          </Grid>
        )}
      </Container>
      <ReproveStatusDialog
        title="Reprovar parceiro"
        infoMessage={constants.partner.REPROVE_DIALOG_INFO_MESSAGE}
        actionButtonText="Reprovar"
        maxWidth="sm"
        open={openReproveDialog}
        setOpen={setOpenReproveDialog}
        action={handleReprove}
      >
        <form onSubmit={handleSubmit(handleReprove)}>
          <Box width="100%" pb={2}>
            <Controller
              as={
                <TextField
                  fullWidth
                  error={!!errors.observations}
                  helperText={errors?.name?.observations}
                  label="Observação"
                />
              }
              control={control}
              name="observations"
              mode="onBlur"
            />
          </Box>
        </form>
      </ReproveStatusDialog>
    </Page>
  )
}

export default PartnersReview
