import React from 'react'

import { Box, Typography, Button } from '@material-ui/core'

import constants from 'constants/index'

import useStyles from '../../styles'

function ReviewPartnerService({
  handleApprove = () => {},
  handleReprove = () => {},
}) {
  const classes = useStyles()

  return (
    <Box m={2}>
      <Typography variant="h5">
        Deseja aprovar ou reprovar este serviço do parceiro?
      </Typography>
      <Box mt={1}>
        <Typography variant="body1">
          No caso do serviço ser reprovado, só será possível revisar novamente
          quando o parceiro enviar para revisão.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button
          color="primary"
          className={classes.buttonApprove}
          variant="contained"
          onClick={() => handleApprove(constants.partnerService.ACTIVE_STATUS)}
        >
          Aprovar
        </Button>
        <Button color="secondary" variant="contained" onClick={handleReprove}>
          Reprovar
        </Button>
      </Box>
    </Box>
  )
}

export default ReviewPartnerService
