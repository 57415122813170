import {
  Card,
  Box,
  Avatar,
  Typography,
  Chip,
  CircularProgress,
  Link,
} from '@material-ui/core'

import { getBaseURL } from 'service/env'

import styles from './styles'

import { usePalette } from 'react-palette'

import helpers from 'helpers'

import { ExternalLink as ExternalLinkIcon } from 'react-feather'

const PartnerCard = ({ partner }) => {
  const { data, loading } = usePalette(
    getBaseURL('marketPlace') + partner.logo.url,
  )
  const useStyles = styles(data.darkVibrant)

  const classes = useStyles()

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card className={classes.root}>
          <Box>
            <Box height="12vh" className={classes.logoRoot}>
              <Box className={classes.logoBox}>
                <Avatar
                  src={getBaseURL('marketPlace') + partner.logo.url}
                  className={classes.logoComponent}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box>
              <Box mt={9}>
                <Typography variant="h5" align="center">
                  {partner.name}
                </Typography>
              </Box>
              {partner.siteUrl && (
                <Box display="flex" justifyContent="center" mt={1}>
                  <Link
                    href={partner.siteUrl}
                    className={classes.linkContainer}
                  >
                    <Typography variant="body1">Visualizar site</Typography>
                    <ExternalLinkIcon width="15px" className={classes.icon} />
                  </Link>
                </Box>
              )}
              <Box display="flex" justifyContent="center" mt={3} mb={2}>
                <Chip
                  size="small"
                  className={helpers.partners.chipStatusColor(
                    partner.status,
                    classes,
                  )}
                  label={helpers.partners.statusName(partner.status)}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  )
}

export default PartnerCard
