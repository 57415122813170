import cpf from './cpf'
import cnpj from './cnpj'
import date from './date'
import errorMessage from './errorMessage'
import cep from './cep'
import phone from './phone'
import hours from './hours'
import documentName from './documentName'
import changeStatus from './changeStatus'

const formatters = {
  cpf,
  date,
  cnpj,
  cep,
  errorMessage,
  phone,
  hours,
  documentName,
  changeStatus,
}

export default formatters
