import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import { TopBar } from './components'
import { Page } from 'components'

import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'service/env'

import useStyles from './styles'

import AOS from 'aos'
import 'aos/dist/aos.css'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import useAuth from 'hooks/useAuth'

const LandingPage = () => {
  const classes = useStyles()
  const location = useLocation()
  const snackbar = useSnackbar()
  const auth = useAuth()
  const history = useHistory()

  const locationState = location.state
  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    AOS.init({
      duration: 1250,
      disable: function () {
        var maxWidth = 800
        return window.innerWidth < maxWidth
      },
    })
  }, [])

  useEffect(() => {
    if (locationState) {
      if (locationState.error) {
        snackbar.open({ message: message, variant: 'error' })
      } else {
        snackbar.open({ message: message, variant: 'success' })
      }
    }
    // eslint-disable-next-line
  }, [message, locationState])

  const loginWithButton = async () => {
    const response = await window.getTokenAuth()

    try {
      await service.marketPlace.auth.login({
        companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
        token: response?.token,
        companySubdomain: 'dponet',
      })
      auth.loadDataWithAnimation()
      history.push(routes.home)
    } catch {
      snackbar.open({
        message:
          'Usuário não tem permissão para fazer login da plataforma de backoffice.',
        variant: 'error',
        duration: 20000,
      })
    }
  }

  const signUpButton = async () => {
    const response = await window.signUpOneID()
    snackbar.open({ message: response.message, variant: response.status })
  }

  return (
    <Page className={classes.banner} title="Backoffice Parceiro DPOnet">
      <input
        type="hidden"
        id="leavening_company_token"
        value={COMPANY_TOKEN_LOGIN_WITH_ID}
      />
      <TopBar loginWithButton={loginWithButton} signUpButton={signUpButton} />
    </Page>
  )
}

export default LandingPage
