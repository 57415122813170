import PartnersMain from './PartnersMain'
import PartnersShow from './PartnersShow'
import PartnersWaitingApproval from './PartnersWaitingApproval'
import PartnersReview from './PartnersReview'

const partners = {
  PartnersMain,
  PartnersShow,
  PartnersWaitingApproval,
  PartnersReview,
}

export default partners
