import { useState } from 'react'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Button, CircularProgress, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import {
  MenuButton,
  Permitted,
  BaseActionDialog as DeleteCompanyServiceDialog,
} from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import { routes } from 'Routes'
import * as service from 'service'

const TableRowCompanyServices = ({
  companyService,
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const [deleteCompanyServiceDialog, setDeleteCompanyServiceDialog] =
    useState(false)

  const handleEdit = (companyServiceId) => {
    history.push(
      reverse(routes.companyServices.edit, {
        companyServiceId: companyServiceId,
      }),
    )
  }

  const handleDetails = (companyServiceId) => {
    history.push(
      reverse(routes.companyServices.show, {
        companyServiceId: companyServiceId,
      }),
    )
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await service.marketPlace.companyService.destroy({
        companyServiceId: companyService.id,
      })
      snackbar.open({
        message: 'Serviço excluído com sucesso',
        variant: 'success',
      })
      setDeleteCompanyServiceDialog(false)
      setLoading(false)
      onEvent()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{companyService.id}</TableCell>
        <TableCell>{companyService.name}</TableCell>
        <TableCell>{companyService?.serviceCategory?.name}</TableCell>
        <TableCell disableTooltip>
          <Chip
            size="small"
            className={helpers.companyServices.chipStatusColor(
              companyService.status,
              classes,
            )}
            label={helpers.companyServices.statusName(companyService.status)}
          />
        </TableCell>
        <TableCell>
          {helpers.formatters.date(companyService.createdAt)}
        </TableCell>
        <Permitted
          someTags={[
            constants.permissions.COMPANY_SERVICES.UPDATE,
            constants.permissions.COMPANY_SERVICES.SHOW,
            constants.permissions.COMPANY_SERVICES.DELETE,
          ]}
        >
          <TableCell align="right" disableTooltip>
            <MenuButton>
              <Permitted tag={constants.permissions.COMPANY_SERVICES.SHOW}>
                <Button
                  fullWidth
                  onClick={() => handleDetails(companyService.id)}
                >
                  Detalhes
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.COMPANY_SERVICES.UPDATE}>
                <Button fullWidth onClick={() => handleEdit(companyService.id)}>
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.COMPANY_SERVICES.DELETE}>
                <Button
                  fullWidth
                  onClick={() => setDeleteCompanyServiceDialog(true)}
                >
                  Excluir
                </Button>
              </Permitted>
            </MenuButton>
          </TableCell>
        </Permitted>
      </TableRow>
      <DeleteCompanyServiceDialog
        title="Excluir serviço"
        infoMessage={constants.companyService.DELETE_DIALOG_INFO_MESSAGE}
        actionButtonText="Excluir"
        maxWidth="sm"
        open={deleteCompanyServiceDialog}
        setOpen={setDeleteCompanyServiceDialog}
        action={handleDelete}
      />
    </>
  )
}

const CompanyServicesTable = ({
  companyServices,
  isLoading,
  onEvent = () => {},
  setLoading = () => {},
}) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum serviço da empresa encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow className={classes.tableHeaderHeight}>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="30%">Nome</TableCell>
                  <TableCell width="20%">Categoria</TableCell>
                  <TableCell width="15%">Status</TableCell>
                  <TableCell width="15%">Data de criação</TableCell>
                  <TableCell width="10%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyServices?.map((companyService) => (
                  <TableRowCompanyServices
                    companyService={companyService}
                    key={companyService.id}
                    setLoading={setLoading}
                    onEvent={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default CompanyServicesTable
