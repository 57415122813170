import { useState, useEffect } from 'react'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Card,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  HelpDialog,
  Page,
} from 'components'
import { PartnersContentList } from './components'
import { useBadge } from 'hooks'
import constants from 'constants/index'
import * as service from 'service'

const PartnerServicesWaitingApproval = () => {
  const filter = useFilter()
  const theme = useTheme()
  const [loading] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { setBadgePartnerWaitingApproved } = useBadge()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.marketPlace.partner.getWaitingApproval,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  useEffect(() => {
    setBadgePartnerWaitingApproved(response?.data?.meta?.totalCount)
    // eslint-disable-next-line
  }, [response?.data?.meta?.totalCount])

  return (
    <Page title="Todos os parceiros">
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Parceiros "
          title="Aguardando aprovação"
          subtitle="Listagem"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box mt={3}>
          <Card>
            <Box
              width="100%"
              p={2}
              height="100%"
              display="flex"
              alignItems="center"
            >
              <Typography variant="h5">
                Parceiros aguardando aprovação
              </Typography>
            </Box>
            <Box display="flex" width="100%">
              <Grid container>
                <PartnersContentList partners={response?.data?.partners} />
              </Grid>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading || loading ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome" name="name" />
          <input
            textfieldinput="true"
            label="Numero do documento"
            name="document"
          />
          <select
            textfieldinput="true"
            label="Tipo do documento"
            name="documentType"
          >
            <option value=""></option>
            <option value="CPF">CPF</option>
            <option value="CNPJ">CNPJ</option>
          </select>
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
          />
          <select textfieldinput="true" label="Tipo do parceiro" name="kind">
            <option value=""></option>
            <option value="C">Jurídico</option>
            <option value="P">Físico</option>
          </select>
        </Filters>
      </Container>
      <HelpDialog
        title={constants.partnerService.HELP_TITLE}
        about={constants.partnerService.HELP_ABOUT}
        helpText={constants.partnerService.HELP_TEXT_WAITING_APPROVAL}
      />
    </Page>
  )
}

export default PartnerServicesWaitingApproval
