import { useState, useEffect } from 'react'

import AuthContext from 'contexts/AuthContext'

import {
  Home as HomeIcon,
  Users as UsersIcon,
  List as ListIcon,
  CheckCircle as CheckCircleIcon,
  Loader as LoaderIcon,
  Link as LinkIcon,
  Briefcase as BriefcaseIcon,
  ShoppingBag as ShoppingIcon,
} from 'react-feather'

import * as service from 'service'
import constants from 'constants/index'

import { useBadge } from 'hooks'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [permissions, setPermissions] = useState(false)
  const [menuItems, setMenuItems] = useState([])
  const [auth, setAuth] = useState(false)

  const {
    badgePartnerServiceWaitingApproved,
    setBadgePartnerServiceWaitingApproved,
    badgePartnerWaitingApproved,
    setBadgePartnerWaitingApproved,
  } = useBadge()

  useEffect(() => {
    loadDataWithAnimation()

    // eslint-disable-next-line
  }, [badgePartnerServiceWaitingApproved, badgePartnerWaitingApproved])

  const loadData = async () => {
    const [
      responseMe,
      responsePermissions,
      responsePartnerService,
      responsePartner,
    ] = await Promise.all([
      service.marketPlace.user.me(),
      service.marketPlace.user.permissions(),
      service.marketPlace.partnerService.getWaitingApproval({}),
      service.marketPlace.partner.getWaitingApproval({}),
    ])
    setUser(responseMe?.data?.user)
    setPermissions(responsePermissions?.data?.profilePermissions)
    setBadgePartnerServiceWaitingApproved(
      responsePartnerService?.data?.meta?.totalCount,
    )
    setBadgePartnerWaitingApproved(responsePartner?.data?.meta?.totalCount)
    const menuItems = [
      {
        subheader: 'DPOnet&Você',
        items: [
          {
            title: 'Inicio',
            icon: HomeIcon,
            href: '/home',
          },
        ],
      },
      {
        subheader: 'Parceiros',
        items: [
          {
            title: 'Todos',
            icon: UsersIcon,
            href: '/partners',
          },
          {
            title: 'Aguardando aprovação',
            icon: LoaderIcon,
            countToBadge: badgePartnerWaitingApproved,
            href: '/partners/waiting-approval',
          },
        ],
      },
      {
        subheader: 'Gerenciamentos',
        somePermissions: [constants.permissions.COMPANY_SERVICES.LIST],
        items: [
          {
            title: 'Serviços da empresa',
            icon: ListIcon,
            href: '/company-services',
            permission: constants.permissions.COMPANY_SERVICES.LIST,
          },
          {
            title: 'Categoria de serviços',
            icon: BriefcaseIcon,
            href: '/service-categories',
            permission: constants.permissions.SERVICE_CATEGORIES.LIST,
          },
          {
            title: 'Links de redirecionamento',
            icon: LinkIcon,
            href: '/redirect-links',
            permissions: constants.permissions.REDIRECT_LINKS.LIST,
          },
        ],
      },
      {
        subheader: 'Serviços dos parceiros',
        somePermissions: [
          constants.permissions.PARTNER_SERVICES.LIST,
          constants.permissions.PARTNER_SERVICES.LIST_WAITING_APPROVAL,
        ],
        items: [
          {
            title: 'Todos',
            icon: CheckCircleIcon,
            href: '/partner-services',
            permission: constants.permissions.PARTNER_SERVICES.LIST,
          },
          {
            title: 'Aguardando aprovação',
            icon: LoaderIcon,
            countToBadge: badgePartnerServiceWaitingApproved,
            href: '/partner-services/waiting-approval',
            permission:
              constants.permissions.PARTNER_SERVICES.LIST_WAITING_APPROVAL,
          },
        ],
      },
      {
        subheader: 'Solicitações',
        somePermissions: [
          constants.permissions.PARTNER_SERVICES.LIST,
          constants.permissions.PARTNER_SERVICES.LIST_WAITING_APPROVAL,
        ],
        items: [
          {
            title: 'Todos',
            icon: ShoppingIcon,
            href: '/orders',
            permission: constants.permissions.PARTNER_SERVICES.LIST,
          },
        ],
      },
    ]
    setMenuItems(menuItems)
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        permissions,
        setUser,
        loaded,
        isLoading,
        menuItems,
        loadDataWithAnimation,
        loadData,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
