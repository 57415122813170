import { Box, Button, Chip } from '@material-ui/core'

import { reverse } from 'named-urls'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { MenuButton } from 'components'

import PerfectScrollbar from 'react-perfect-scrollbar'

import useStyles from './styles'

import helpers from 'helpers'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import constants from 'constants/index'

const TableRowPartner = ({ partner, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const navigateToShow = (partnerId) => {
    history.push(
      reverse(routes.partners.show, {
        partnerId: partnerId,
      }),
    )
  }

  const navigateToReview = (partnerId) => {
    history.push(
      reverse(routes.partners.review, {
        partnerId: partnerId,
      }),
    )
  }

  return (
    <TableRow {...rest}>
      <TableCell>{partner.id}</TableCell>
      <TableCell>{partner.name}</TableCell>
      <TableCell>
        {partner.documentType === 'CPF'
          ? helpers.formatters.cpf(partner.document)
          : helpers.formatters.cnpj(partner.document)}
      </TableCell>
      <TableCell>{helpers.formatters.date(partner.createdAt)}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={helpers.partners.chipStatusColor(partner.status, classes)}
          label={helpers.partners.statusName(partner.status)}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              navigateToShow(partner.id)
            }}
          >
            Visualizar
          </Button>
          {partner.status ===
            constants.partner.status.WAITING_APPROVAL_STATUS && (
            <Button
              fullWidth
              size="small"
              onClick={() => {
                navigateToReview(partner.id)
              }}
            >
              Analisar
            </Button>
          )}
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const PartnersContentList = ({ partners }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table size="small" emptyMessage="Nenhum parceiro encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="7%">id</TableCell>
              <TableCell width="25%" align="left">
                Nome
              </TableCell>
              <TableCell width="25%" align="left">
                Documento
              </TableCell>
              <TableCell width="23%" align="left">
                Data de criação
              </TableCell>
              <TableCell width="15%" align="left">
                Status
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {partners &&
              partners.map((partner) => (
                <TableRowPartner partner={partner} key={partner.id} hover />
              ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default PartnersContentList
